/* eslint-disable no-unused-vars */
import { formEditAddressClassName } from 'components/FormAddTransaction/components/FormEditAddress';
import { formAddBankAccountClassName } from 'components/FormEditReceiver/components/FormAddNewBankAccount';
import { formAddEwalletAccountClassName } from 'components/FormEditReceiver/components/FormAddNewEwalletAccount';
import { LogUtil } from 'components/common/LogUtil';
import useGetElementByClassName from 'hooks/useGetElementByClassName';
import { isEmpty, isObject } from 'lodash';
import { compareObject } from '../helpers/compares';
import { transformServiceAlertsData } from '../helpers/transforms';
import { ServiceAlertsStoreModal } from '../model/ServiceAlertsLocalStore';
import {
  SERVICE_ALERTS_TYPE,
  ServiceAlertsModel,
} from '../model/ServiceAlertsModel';
import useServiceAlertsModal from '../useServiceAlertsModal';

const TAG = 'service-alerts-listener';

const serviceAlertsStoreVariant: any = {
  countryCode: 'serviceAlertsByCountryCode',
  payoutMethod: 'serviceAlertsByPayoutMethod',
  payoutMethodItem: 'serviceAlertsByPayoutMethodItem',
  payoutMethodItemChild: 'serviceAlertsByPayoutMethodItemChild',
  currencyCode1: 'serviceAlertsByCurrencyCode1',
  currencyCode2: 'serviceAlertsByCurrencyCode2',
  rangeOfCurrencyCode1: 'serviceAlertsByRangeOfCurrencyCode1',
  rangeOfCurrencyCode2: 'serviceAlertsByRangeOfCurrencyCode2',
  paymentMethod: 'serviceAlertsByPaymentMethod',
};

interface Props {
  variant: string;
  modalOnReset?: (serviceAlertsStore?: ServiceAlertsStoreModal) => void;
  modalOnClick?: (serviceAlertsStore?: ServiceAlertsStoreModal) => void;
}
const useServiceAlertsListener = ({
  variant,
  modalOnReset = () => {},
  modalOnClick = () => {},
}: Props) => {
  const { className: screenName1 } = useGetElementByClassName(
    formEditAddressClassName
  );
  const { className: screenName2 } = useGetElementByClassName(
    formAddBankAccountClassName
  );
  const { className: screenName3 } = useGetElementByClassName(
    formAddEwalletAccountClassName
  );

  const {
    isServiceAlertsModalShowing,
    currentModal,
    handleShowServiceAlertsModal,
    closeServiceAlertsModal,
  } = useServiceAlertsModal();

  const serviceAlertsStoreProp: string =
    serviceAlertsStoreVariant?.[variant] || '';

  const getCurrentServiceAlertsStore = ({
    serviceAlertsStore,
  }: {
    serviceAlertsStore: ServiceAlertsStoreModal | any;
  }) => {
    if (serviceAlertsStore) {
      return serviceAlertsStore?.[serviceAlertsStoreProp] || null;
    }

    return null;
  };

  const onCheck = ({
    serviceAlertsStore,
    isChange = false,
    isPreventShowServiceAlertsModal = false,
  }: {
    serviceAlertsStore: ServiceAlertsStoreModal;
    isChange?: boolean;
    isPreventShowServiceAlertsModal?: boolean;
  }) => {
    const { message, type } =
      getCurrentServiceAlertsStore({ serviceAlertsStore }) || {};

    const isCloseServiceAlertsModal =
      isServiceAlertsModalShowing && isChange && !type;
    if (isCloseServiceAlertsModal) {
      if (!isPreventShowServiceAlertsModal) {
        LogUtil.log(TAG, `ACTION xxxx closeServiceAlertsModal xxxx ${variant}`);
        closeServiceAlertsModal();
      }

      if (modalOnClick) {
        modalOnClick(serviceAlertsStore);
      }
    }

    if (isPreventShowServiceAlertsModal) return;
    const routesPreventShowServiceAlertsModal = [
      screenName1,
      screenName2,
      screenName3,
    ];
    const isHandleShowServiceAlertsModal =
      type &&
      message &&
      (!currentModal || isServiceAlertsModalShowing) &&
      !routesPreventShowServiceAlertsModal.find((screenName) => screenName);
    if (isHandleShowServiceAlertsModal) {
      LogUtil.log(
        TAG,
        `ACTION xxxx handleShowServiceAlertsModal xxxx ${variant}`
      );

      const getModalOnClick = () => {
        if (type === SERVICE_ALERTS_TYPE.DISABLE && modalOnReset)
          return () => modalOnReset(serviceAlertsStore);
        if (type === SERVICE_ALERTS_TYPE.DELAY && modalOnClick)
          return () => modalOnClick(serviceAlertsStore);
        return () => {};
      };
      handleShowServiceAlertsModal({
        message,
        type,
        onClick: getModalOnClick(),
      });
    }
  };

  const onGet = ({
    data,
    serviceAlertsStore,
    isPreventShowServiceAlertsModal,
  }: {
    data: ServiceAlertsModel;
    serviceAlertsStore: ServiceAlertsStoreModal;
    isPreventShowServiceAlertsModal?: boolean;
  }) => {
    const newServiceAlertsStore = {
      ...serviceAlertsStore,
      [serviceAlertsStoreProp]: data,
    };

    onCheck({
      serviceAlertsStore: newServiceAlertsStore,
      isPreventShowServiceAlertsModal,
    });

    return newServiceAlertsStore;
  };

  const onChange = ({
    data,
    serviceAlertsStore,
    isPreventShowServiceAlertsModal,
  }: {
    data: ServiceAlertsModel;
    serviceAlertsStore: ServiceAlertsStoreModal;
    isPreventShowServiceAlertsModal?: boolean;
  }) => {
    const newServiceAlertsStore = {
      ...serviceAlertsStore,
      [serviceAlertsStoreProp]: data,
    };

    onCheck({
      serviceAlertsStore: newServiceAlertsStore,
      isChange: true,
      isPreventShowServiceAlertsModal,
    });

    return newServiceAlertsStore;
  };

  const onListener = ({
    data = null,
    serviceAlertsStore,
    isPreventOnGet = false,
    isPreventShowServiceAlertsModal = false,
  }: {
    data: ServiceAlertsModel | null;
    serviceAlertsStore: ServiceAlertsStoreModal;
    isPreventOnGet?: boolean;
    isPreventShowServiceAlertsModal?: boolean;
  }) => {
    const dataTransform = transformServiceAlertsData(data);

    const currentServiceAlertsStore = getCurrentServiceAlertsStore({
      serviceAlertsStore,
    });

    const isGetNewestServiceAlerts =
      !isPreventOnGet &&
      isObject(dataTransform) &&
      isEmpty(currentServiceAlertsStore);
    if (isGetNewestServiceAlerts) {
      LogUtil.log(
        TAG,
        `onGet xxxx ${variant} xxxx data xxxx`,
        JSON.stringify(dataTransform)
      );
      return onGet({
        data: dataTransform,
        serviceAlertsStore,
        isPreventShowServiceAlertsModal,
      });
    }

    const isChangeNewestServiceAlertsStore =
      isObject(dataTransform) &&
      !isEmpty(currentServiceAlertsStore) &&
      !compareObject(dataTransform, currentServiceAlertsStore);
    if (isChangeNewestServiceAlertsStore) {
      LogUtil.log(
        TAG,
        `onChange xxxx ${variant} xxxx data xxxx`,
        JSON.stringify(dataTransform)
      );
      return onChange({
        data: dataTransform,
        serviceAlertsStore,
        isPreventShowServiceAlertsModal,
      });
    }

    return serviceAlertsStore;
  };

  return { onListener };
};

export default useServiceAlertsListener;
