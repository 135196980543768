/* eslint-disable no-unused-vars */
import { LogUtil } from 'components/common/LogUtil';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import useQuery from 'hooks/useQuery';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { compareServiceAlertsStore } from '../helpers/compares';
import useServiceAlertsByCountryCodeListener from '../listeners/useServiceAlertsByCountryCodeListener';
import {
  ServiceAlertsByCountryCodeDefault,
  ServiceAlertsByPaymentsDefault,
  ServiceAlertsStoreModal,
} from '../model/ServiceAlertsLocalStore';
import {
  SERVICE_ALERTS_TYPE,
  ServiceAlertsModel,
} from '../model/ServiceAlertsModel';
import useCheckServiceAlerts from '../useCheckServiceAlerts';
import useServiceAlertsConfig from '../useServiceAlertsConfig';
import useServiceAlertsStore from '../useServiceAlertsStore';
import useServiceAlertsObserver from './useServiceAlertsObserver';

const TAG = 'service-alerts-countryCode-observer';

interface Props {
  dbPath?: string;
  variant: string;
}
const useServiceAlertsByCountryCodeObserver = ({ dbPath, variant }: Props) => {
  const query = useQuery();
  const refNumber = query.get('refNumber');
  const isMakePayment = Cookies.get(MAKE_PAYMENT_KEY) || refNumber;
  const isPreventObserver = isMakePayment;

  const {
    serviceAlertsByCountryCode: serviceAlertsStore,
    setServiceAlertsByCountryCode: setServiceAlerts,
    setServiceAlertsByPayments,
  } = useServiceAlertsStore();

  const {
    countryCode,
    payoutMethod,
    payoutMethodByBank,
    payoutMethodItem,
    currencyCode1,
    currencyCode2,
    receivingAmount1,
    receivingAmount2,
  } = useServiceAlertsConfig();

  const [serviceAlertsByCountryCodeData, setServiceAlertsByCountryCodeData] =
    useState<ServiceAlertsModel | null>(null);

  const { fetchServiceAlertsListener, closeServiceAlertsListener } =
    useServiceAlertsObserver({
      variant,
      dbPath: dbPath || '',
    });

  const {
    checkByCountryCode,
    checkByPayoutMethod,
    checkByPayoutMethodItem,
    checkByPayoutMethodItemChild,
    checkByCurrencyCode,
    checkByRangeOfCurrencyCode,
  } = useCheckServiceAlerts();
  const { getPreventShowServiceAlertsModalByCountryCode } = checkByCountryCode;
  const { getPreventShowServiceAlertsModalByPayoutMethod } =
    checkByPayoutMethod;
  const {
    getServiceAlertsByPayoutMethodItem,
    getPayoutMethodItemId,
    getPreventShowServiceAlertsModalByPayoutMethodItem,
  } = checkByPayoutMethodItem;
  const {
    getPreventShowServiceAlertsModalByPayoutMethodItemChild,
    getServiceAlertsByPayoutMethodItemChild,
    getPayoutMethodItemChildId,
  } = checkByPayoutMethodItemChild;

  const {
    getServiceAlertsByCurrencyCode,
    getPreventShowServiceAlertsModalByCurrencyCode,
  } = checkByCurrencyCode();
  const {
    getServiceAlertsByRangeOfCurrencyCode,
    getPreventShowServiceAlertsModalByRangeOfCurrencyCode,
  } = checkByRangeOfCurrencyCode();

  useEffect(() => {
    const isAllowConnectToRealtimeDb =
      !isPreventObserver && countryCode && dbPath;

    if (isAllowConnectToRealtimeDb) {
      fetchServiceAlertsListener({
        path: dbPath,
        setServiceAlerts: setServiceAlertsByCountryCodeData,
      });
    }

    return () => {
      if (isAllowConnectToRealtimeDb) {
        closeServiceAlertsListener({
          path: dbPath,
        });

        setServiceAlertsByCountryCodeData(null);

        setServiceAlerts(ServiceAlertsByCountryCodeDefault);

        setServiceAlertsByPayments(ServiceAlertsByPaymentsDefault);
      }
    };
  }, [isPreventObserver, countryCode, dbPath]);

  const {
    countryCodeListener,
    payoutMethodListener,
    payoutMethodItemListener,
    payoutMethodItemChildListener,
    currencyCode1Listener,
    currencyCode2Listener,
    rangeOfCurrencyCode1Listener,
    rangeOfCurrencyCode2Listener,
  } = useServiceAlertsByCountryCodeListener();
  useEffect(() => {
    if (!isPreventObserver && !isEmpty(serviceAlertsByCountryCodeData)) {
      const serviceAlertsByCountryCode = serviceAlertsByCountryCodeData;
      const serviceAlertsByPayoutMethod =
        serviceAlertsByCountryCodeData?.[payoutMethod || ''];
      const payoutItemId =
        payoutMethodItem &&
        serviceAlertsByPayoutMethod?.type !== SERVICE_ALERTS_TYPE.DISABLE &&
        getPayoutMethodItemId(payoutMethod || '');
      const serviceAlertsByPayoutMethodItem =
        payoutItemId &&
        getServiceAlertsByPayoutMethodItem({
          payoutMethod: payoutMethod || '',
          serviceAlertsByPayoutMethod,
        });
      const payoutItemChildId =
        payoutMethodItem &&
        serviceAlertsByPayoutMethod?.type !== SERVICE_ALERTS_TYPE.DISABLE &&
        getPayoutMethodItemChildId(payoutMethod || '');
      const serviceAlertsByPayoutMethodItemChild =
        payoutItemChildId &&
        getServiceAlertsByPayoutMethodItemChild({
          payoutMethod: payoutMethod || '',
          serviceAlertsByPayoutMethodItem,
        });
      const serviceAlertsByCurrencyCode1 =
        currencyCode1 &&
        getServiceAlertsByCurrencyCode({
          currencyCode: currencyCode1,
          serviceAlertsByCountryCode,
          serviceAlertsByPayoutMethod,
          serviceAlertsByPayoutMethodItem,
          serviceAlertsByPayoutMethodItemChild:
            serviceAlertsByPayoutMethodItem?.[payoutItemChildId],
        });
      const serviceAlertsByCurrencyCode2 =
        currencyCode2 &&
        getServiceAlertsByCurrencyCode({
          currencyCode: currencyCode2,
          serviceAlertsByCountryCode,
          serviceAlertsByPayoutMethod,
          serviceAlertsByPayoutMethodItem,
          serviceAlertsByPayoutMethodItemChild:
            serviceAlertsByPayoutMethodItem?.[payoutItemChildId],
        });
      const serviceAlertsByRangeOfCurrencyCode1: any =
        (receivingAmount1 &&
          getServiceAlertsByRangeOfCurrencyCode({
            currencyCode: currencyCode1,
            receivingAmount: receivingAmount1,
            serviceAlertsByCountryCode,
            serviceAlertsByPayoutMethod,
            serviceAlertsByPayoutMethodItem,
            serviceAlertsByPayoutMethodItemChild:
              serviceAlertsByPayoutMethodItem?.[payoutItemChildId],
          })) ||
        null;
      const currencyCode1ContainRange =
        receivingAmount1 && !isEmpty(serviceAlertsByRangeOfCurrencyCode1);
      const serviceAlertsByRangeOfCurrencyCode2: any =
        (receivingAmount2 &&
          getServiceAlertsByRangeOfCurrencyCode({
            currencyCode: currencyCode2,
            receivingAmount: receivingAmount2,
            serviceAlertsByCountryCode,
            serviceAlertsByPayoutMethod,
            serviceAlertsByPayoutMethodItem,
            serviceAlertsByPayoutMethodItemChild:
              serviceAlertsByPayoutMethodItem?.[payoutItemChildId],
          })) ||
        null;
      const currencyCode2ContainRange =
        receivingAmount2 && !isEmpty(serviceAlertsByRangeOfCurrencyCode2);

      let newServiceAlertsStore: ServiceAlertsStoreModal | any = {
        ...serviceAlertsStore,
        serviceAlertsByCountryCodeData,
        ...(!countryCode && { serviceAlertsByCountryCodeData: null }),
        ...(!countryCode && { serviceAlertsByCountryCode: null }),
        ...(!payoutMethod && { serviceAlertsByPayoutMethod: null }),
        ...(!payoutItemId && { serviceAlertsByPayoutMethodItem: null }),
        ...(!payoutMethodByBank && { showValidateBankAccountModal: null }),
        ...(!payoutItemChildId && {
          serviceAlertsByPayoutMethodItemChild: null,
        }),
        ...(!currencyCode1 && { serviceAlertsByCurrencyCode1: null }),
        ...(!currencyCode2 && { serviceAlertsByCurrencyCode2: null }),
        ...(!currencyCode1ContainRange && {
          serviceAlertsByRangeOfCurrencyCode1: null,
        }),
        ...(!currencyCode2ContainRange && {
          serviceAlertsByRangeOfCurrencyCode2: null,
        }),
      };

      if (countryCode) {
        newServiceAlertsStore = countryCodeListener({
          data: serviceAlertsByCountryCode,
          serviceAlertsStore: newServiceAlertsStore,
          isPreventShowServiceAlertsModal:
            getPreventShowServiceAlertsModalByCountryCode(),
        });
      }
      if (payoutMethod) {
        newServiceAlertsStore = payoutMethodListener({
          data: serviceAlertsByPayoutMethod,
          serviceAlertsStore: newServiceAlertsStore,
          isPreventShowServiceAlertsModal:
            getPreventShowServiceAlertsModalByPayoutMethod({
              serviceAlertsStore: newServiceAlertsStore,
              payoutItemId,
              payoutItemChildId,
            }),
        });
      }
      if (payoutItemId) {
        newServiceAlertsStore = payoutMethodItemListener({
          data: serviceAlertsByPayoutMethodItem,
          serviceAlertsStore: newServiceAlertsStore,
          isPreventShowServiceAlertsModal:
            getPreventShowServiceAlertsModalByPayoutMethodItem({
              serviceAlertsStore: newServiceAlertsStore,
              payoutItemId,
              payoutItemChildId,
            }),
        });
      }
      if (payoutItemChildId) {
        newServiceAlertsStore = payoutMethodItemChildListener({
          data: serviceAlertsByPayoutMethodItemChild,
          serviceAlertsStore: newServiceAlertsStore,
          isPreventShowServiceAlertsModal:
            getPreventShowServiceAlertsModalByPayoutMethodItemChild({
              serviceAlertsStore: newServiceAlertsStore,
              payoutMethod: payoutMethod || '',
              payoutItemId,
              payoutItemChildId,
            }),
        });
      }
      if (currencyCode1) {
        newServiceAlertsStore = currencyCode1Listener({
          data: serviceAlertsByCurrencyCode1,
          serviceAlertsStore: newServiceAlertsStore,
          isPreventShowServiceAlertsModal:
            getPreventShowServiceAlertsModalByCurrencyCode({
              serviceAlertsStore: newServiceAlertsStore,
              payoutMethod,
              payoutItemId,
              payoutItemChildId,
              serviceAlertByCurrencyCode: serviceAlertsByCurrencyCode1,
              serviceAlertsByRangeOfCurrencyCode: null,
            }),
        });
      }
      if (currencyCode2) {
        newServiceAlertsStore = currencyCode2Listener({
          data: serviceAlertsByCurrencyCode2,
          serviceAlertsStore: newServiceAlertsStore,
          isPreventShowServiceAlertsModal:
            getPreventShowServiceAlertsModalByCurrencyCode({
              serviceAlertsStore: newServiceAlertsStore,
              payoutMethod,
              payoutItemId,
              payoutItemChildId,
              serviceAlertByCurrencyCode: serviceAlertsByCurrencyCode2,
              serviceAlertsByRangeOfCurrencyCode: null,
            }),
        });
      }
      if (currencyCode1ContainRange) {
        newServiceAlertsStore = rangeOfCurrencyCode1Listener({
          data: serviceAlertsByRangeOfCurrencyCode1,
          serviceAlertsStore: newServiceAlertsStore,
          isPreventOnGet:
            serviceAlertsByRangeOfCurrencyCode1?.type !==
            SERVICE_ALERTS_TYPE.DISABLE,
          isPreventShowServiceAlertsModal:
            getPreventShowServiceAlertsModalByRangeOfCurrencyCode({
              serviceAlertsStore: newServiceAlertsStore,
              payoutMethod,
              payoutItemId,
              payoutItemChildId,
              serviceAlertByCurrencyCode: serviceAlertsByCurrencyCode1,
            }),
        });
      }
      if (currencyCode2ContainRange) {
        newServiceAlertsStore = rangeOfCurrencyCode2Listener({
          data: serviceAlertsByRangeOfCurrencyCode2,
          serviceAlertsStore: newServiceAlertsStore,
          isPreventOnGet:
            serviceAlertsByRangeOfCurrencyCode2?.type !==
            SERVICE_ALERTS_TYPE.DISABLE,
          isPreventShowServiceAlertsModal:
            getPreventShowServiceAlertsModalByRangeOfCurrencyCode({
              serviceAlertsStore: newServiceAlertsStore,
              payoutMethod,
              payoutItemId,
              payoutItemChildId,
              serviceAlertByCurrencyCode: serviceAlertsByCurrencyCode2,
            }),
        });
      }

      const isServiceAlertsStoreChange = !compareServiceAlertsStore(
        serviceAlertsStore,
        newServiceAlertsStore
      );
      if (isServiceAlertsStoreChange) {
        LogUtil.log(
          TAG,
          `serviceAlertsStore xxxx data xxxx ${variant}`,
          JSON.stringify(newServiceAlertsStore)
        );
        setServiceAlerts(newServiceAlertsStore);
      }
    }
  }, [
    isPreventObserver,
    serviceAlertsByCountryCodeData,
    serviceAlertsStore,
    countryCode,
    payoutMethod,
    payoutMethodItem,
    currencyCode1,
    currencyCode2,
    receivingAmount1,
    receivingAmount2,
  ]);
};

export default useServiceAlertsByCountryCodeObserver;
