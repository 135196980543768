/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import { api } from 'api';
import AddIcon from 'assets/icons/add-black-icon.svg';
import AddCircleOutlineIcon from 'assets/icons/add-receiver-outline-icon.svg';
import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import {
  fetchReceiverInformation,
  isIdSelected,
} from 'components/FormAddTransaction/func';
import {
  addNewBankAccount,
  updateBankAccount,
} from 'components/Receivers/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  ADD_BANK_ACCOUNT_HOLDER_NAME,
  ADD_BANK_ACCOUNT_NUMBER_NAME,
  ADD_BANK_NAME,
  ADD_BANK_SWIFT_NAME,
  ADD_TRANSACTION_METHOD_BANK_NAME,
  ADD_TRANSACTION_RECEIVER_NAME,
  METHOD_BANK,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import { arrayUtils, stringUtils } from 'utils';

import BankNumberAccountPopup from 'components/FormEditReceiver/components/BankNumberAccountPopup';
import DeleteBankAccountPopup from 'components/FormEditReceiver/components/DeleteBankAccountPopup';
import {
  default as FormAddNewBankAccount,
  default as FormUpdateBankAccount,
} from 'components/FormEditReceiver/components/FormAddNewBankAccount';
import {
  getBankSelectVerification,
  getBankUpdateVerification,
  getBanksAdd,
  getFullNameReceiver,
  getNumberUnmaskStr,
} from 'components/FormEditReceiver/func';
import CustomActionDropdown from 'components/Receivers/components/CustomActionDropdown';
import InformPopup from 'components/common/InformPopup';
import {
  ADD_BANK_BSB_NAME,
  CREATE_BUSINESS_RECEIVER_TYPE,
  CREATE_INDIVIDUAL_RECEIVER_TYPE,
  DESKTOP_MIN_WIDTH,
  RECEIVERS_ADD_SUFIX,
} from 'constants';
import { formatter } from 'helpers';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import useReceiver from 'hooks/receiver/useReceiver';
import { compareObject } from 'hooks/service-alerts/helpers/compares';
import { transformServiceAlertsData } from 'hooks/service-alerts/helpers/transforms';
import { SERVICE_ALERTS_TYPE } from 'hooks/service-alerts/model/ServiceAlertsModel';
import useCheckServiceAlerts from 'hooks/service-alerts/useCheckServiceAlerts';
import useServiceAlertsModal from 'hooks/service-alerts/useServiceAlertsModal';
import useServiceAlertsStore from 'hooks/service-alerts/useServiceAlertsStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import { useNavigate } from 'react-router-dom';
import FormInputRadioCheckbox from '../../FormInputRadioCheckbox';

const initialFormField = RESET_EDIT_RECEIVER_INFORMATION_STORE.accountDetails;
const initialFormBank =
  RESET_EDIT_RECEIVER_INFORMATION_STORE.accountDetails.bank;

const BankPayoutInformation = ({
  countryConfig,
  currentReceiver,
  information,
  setInformation,
  validation,
  setValidation,
  isDisabled,
}) => {
  const { t } = useLang();
  const navigate = useNavigate();
  const { state, dispatch } = useStore();
  const { token } = useAuth();
  const { addTransactionCurrentStep: currentStep } = state;

  const [receiverInformation, setReceiverInformation] =
    useState(initialFormField);
  const [bankInformation, setBankInformation] = useState(initialFormBank);
  const [bankValidation, setBankValidation] = useState(initialFormBank);
  const [isOpenFormAddNewBankPopup, setOpenFormAddNewBankPopup] =
    useState(false);
  const [isOpenUpdateBankPopup, setOpenUpdateBankPopup] = useState(false);
  const [isOpenDeleteBankPopup, setOpenDeleteBankPopup] = useState(false);
  const [isOpenUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [isOpenBankNumberAccountPopup, setOpenBankNumberAccountPopup] =
    useState(false);
  const [isOpenNoticePopup, setOpenNoticePopup] = useState(false);
  const [bankInformationRecheck, setBankInformationRecheck] = useState(null);
  const [banks, setBanks] = useState(null);

  const { currentPayoutItemSelectedIndex } = information;

  const { isDesktop } = useDetectDevice();

  const { setPayoutMethodItemByBANK, setCurrentBusinessReceiver } =
    useFormAddTransactionStore();

  const { isReceiverOfBusinessType, isReceiverOfIndividualType } =
    useReceiver();

  const { closeServiceAlertsModal } = useServiceAlertsModal();
  const { serviceAlertsByCountryCode: serviceAlertsStore } =
    useServiceAlertsStore();
  const { serviceAlertsByCountryCodeData, serviceAlertsByPayoutMethodItem } =
    serviceAlertsStore;
  const { checkByPayoutMethodItem } = useCheckServiceAlerts();
  const {
    checkServiceAlertsOfPayoutMethodItemItemDisable,
    saveValidateBankAccountModalIntoServiceAlertsStore,
  } = checkByPayoutMethodItem;

  const config = useGetConfig({ countryConfig });
  const { accountHolder, accountNumber, accountSwift, accountBsb } = config;

  const isApiEnabled = accountNumber?.apiEnabled;

  const accountNumberFormat = formatter.formatParse(accountNumber?.format);
  const bsbNumberFormat = formatter.formatParse(accountBsb?.format);

  const { lastName, firstName } = receiverInformation || currentReceiver || {};

  const receiverFullName = getFullNameReceiver({
    isReceiverOfBusinessType,
    isReceiverOfIndividualType,
    lastName,
    firstName,
    countryCode: countryConfig?.countryCode,
  });

  const accountHolderName =
    receiverFullName || bankInformation[ADD_BANK_ACCOUNT_HOLDER_NAME];

  useEffect(() => {
    if (currentReceiver) {
      setReceiverInformation(currentReceiver);
    }
  }, [currentReceiver]);

  useEffect(() => {
    if (currentPayoutItemSelectedIndex === -1) {
      const newInformation = {
        ...information,
        [ADD_TRANSACTION_METHOD_BANK_NAME]: null,
        currentPayoutItemSelectedIndex: -1,
      };

      setInformation(newInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPayoutItemSelectedIndex]);

  const handleOnOpenDeleteBankAccountPopup = (bank) => {
    const newBankInformation = {
      id: bank?.id,
      bankId: bank?.bankId,
      [ADD_BANK_NAME]: bank?.bankName,
      [ADD_BANK_ACCOUNT_HOLDER_NAME]: bank?.accountName,
      [ADD_BANK_ACCOUNT_NUMBER_NAME]: bank?.accountNumber,
      [ADD_BANK_SWIFT_NAME]: bank?.swiftCode,
      [ADD_BANK_BSB_NAME]: bank?.branchCode,
    };
    setBankInformation(newBankInformation);

    setOpenDeleteBankPopup(true);
  };

  const handleOnCloseDeleteBankAccountPopup = () => {
    setBankInformation(initialFormBank);

    setOpenDeleteBankPopup(false);
  };

  const handleOnClickDeleteBankPopup = async () => {
    dispatch({ type: SET_SHOW_LOADING, payload: true });

    try {
      await api.deleteBank(
        token,
        currentReceiver?.receiverId,
        bankInformation?.id
      );

      setOpenDeleteBankPopup(false);

      setValidation({
        ...validation,
        [ADD_TRANSACTION_METHOD_BANK_NAME]: '',
      });

      fetchReceiverInformation(
        token,
        currentReceiver?.receiverId,
        information,
        currentStep,
        bankInformation?.id
      );

      dispatch({ type: SET_SHOW_LOADING, payload: false });
    } catch (error) {
      console.error(error?.message);

      dispatch({ type: SET_SHOW_LOADING, payload: false });
    }
  };

  const handleOnOpenFormUpdateBankAccountPopup = (bank) => {
    const newBankInformation = {
      id: bank?.id,
      bankId: bank?.bankId,
      [ADD_BANK_NAME]: bank?.bankName,
      [ADD_BANK_ACCOUNT_HOLDER_NAME]: bank?.accountName,
      [ADD_BANK_ACCOUNT_NUMBER_NAME]: bank?.accountNumber,
      [ADD_BANK_SWIFT_NAME]: bank?.swiftCode,
      [ADD_BANK_BSB_NAME]: bank?.branchCode,
    };
    setBankInformation(newBankInformation);

    setOpenUpdateBankPopup(true);

    setOpenBankNumberAccountPopup(false);
  };

  const handleOnCloseFormUpdateBankAccountPopup = () => {
    setBankInformation(initialFormBank);

    setOpenUpdateBankPopup(false);
  };

  const handleUpdateBankAccount = async (
    bankUpdate,
    bankInformation,
    isUpdateTrigger
  ) => {
    dispatch({ type: SET_SHOW_LOADING, payload: true });

    const { bankValidate } = bankInformation || {};
    let { holderName } = bankValidate || {};
    holderName = holderName || accountHolderName;

    let bank = {
      id: bankUpdate?.id,
      bankId: bankUpdate?.bankId,
      bankName: bankUpdate?.bankName,
      accountName: bankUpdate?.accountName || holderName,
      accountNumber: bankUpdate?.accountNumber,
      swiftCode: bankUpdate?.swiftCode,
      branchCode: bankUpdate?.branchCode,
    };

    if (bankInformationRecheck) {
      bank = getBankSelectVerification({
        bankValidate,
        bankSelect: bank,
        countryCode: countryConfig?.countryCode,
        receiverFullName: accountHolderName,
      });
    } else {
      bank = getBankUpdateVerification({
        bankValidate,
        bankUpdate: bank,
        countryCode: countryConfig?.countryCode,
        receiverFullName: accountHolderName,
      });
    }

    try {
      const { nameMatched, serviceStatus, valid } = bankValidate || {};
      const isServiceActive = serviceStatus === 'active';
      const isServiceNotAvailable = !nameMatched && !isServiceActive && !valid;

      if (isUpdateTrigger || !isServiceNotAvailable) {
        const { data: bankUpdateRes } = await updateBankAccount(
          token,
          currentReceiver?.receiverId,
          bank
        );

        if (bankUpdateRes) {
          const newInformation = {
            ...information,
            [ADD_TRANSACTION_METHOD_BANK_NAME]: {
              ...information[ADD_TRANSACTION_METHOD_BANK_NAME],
              bank: { ...bankUpdateRes, bankId: bankUpdate?.bankId },
            },
            currentPayoutItemSelectedIndex: bankUpdateRes?.id,
          };

          fetchReceiverInformation(
            token,
            currentReceiver?.receiverId,
            newInformation,
            currentStep
          );

          handleOnCloseFormUpdateBankAccountPopup();

          if (!bankInformationRecheck) {
            handleOnToggleUpdatePopup();
          }
        }
      }

      setOpenBankNumberAccountPopup(false);

      dispatch({ type: SET_SHOW_LOADING, payload: false });
    } catch (error) {
      console.error(error?.message);

      setOpenBankNumberAccountPopup(false);

      dispatch({ type: SET_SHOW_LOADING, payload: false });
    }
  };

  const handleOnToggleFormAddNewBank = () => {
    setBankInformation(initialFormBank);

    setOpenFormAddNewBankPopup(!isOpenFormAddNewBankPopup);
  };

  const handleOnClickFormAddNewBank = async (bank) => {
    let newInformation = { ...information };

    if (currentReceiver?.banks?.length === 0) {
      newInformation = {
        ...newInformation,
        [ADD_TRANSACTION_RECEIVER_NAME]: {
          ...information[ADD_TRANSACTION_RECEIVER_NAME],
          banks: [bank],
        },
      };
    } else {
      newInformation = {
        ...newInformation,
        [ADD_TRANSACTION_RECEIVER_NAME]: {
          ...information[ADD_TRANSACTION_RECEIVER_NAME],
          banks: [...currentReceiver.banks, bank],
        },
      };
    }
    const currentBanks = newInformation[ADD_TRANSACTION_RECEIVER_NAME].banks;

    const banksAdd = getBanksAdd(currentBanks, currentReceiver?.banks);

    if (!arrayUtils.isArrayEmpty(banksAdd)) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      const bankPromises = banksAdd.map((bank) =>
        addNewBankAccount(token, currentReceiver?.receiverId, bank)
      );

      try {
        await Promise.all(bankPromises);

        fetchReceiverInformation(
          token,
          currentReceiver?.receiverId,
          information,
          currentStep
        );

        dispatch({ type: SET_SHOW_LOADING, payload: false });

        setOpenFormAddNewBankPopup(false);

        setValidation({
          ...validation,
          [ADD_TRANSACTION_METHOD_BANK_NAME]: '',
        });
      } catch (error) {
        console.error(error?.message);

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    }

    if (arrayUtils.isArrayEmpty(banksAdd)) {
      setOpenFormAddNewBankPopup(false);
    }
  };

  const handleOnToggleUpdatePopup = () => {
    setOpenUpdatePopup(!isOpenUpdatePopup);
  };

  const handleOnOpenBankAccountNumberPopup = () => {
    setOpenBankNumberAccountPopup(true);
  };

  const handleOnOpenNoticePopup = (bank) => {
    const newBankInformation = {
      id: bank?.id,
      bankId: bank?.bankId,
      [ADD_BANK_NAME]: bank?.bankName,
      [ADD_BANK_ACCOUNT_HOLDER_NAME]: bank?.accountName,
      [ADD_BANK_ACCOUNT_NUMBER_NAME]: bank?.accountNumber,
      [ADD_BANK_SWIFT_NAME]: bank?.swiftCode,
      [ADD_BANK_BSB_NAME]: bank?.branchCode,
    };
    setBankInformation(newBankInformation);

    setOpenNoticePopup(true);
  };

  const handleNumberValidation = async (id, currentBankSelected) => {
    try {
      if (isApiEnabled) {
        dispatch({ type: SET_SHOW_LOADING, payload: true });

        const { data } = await api.validateAccNumber(
          countryConfig?.countryCode,
          id,
          currentBankSelected?.accountNumber,
          firstName,
          lastName || ''
        );

        if (data) {
          setBankInformationRecheck({
            bankValidate: data,
          });

          const {
            nameMatched,
            serviceStatus,
            valid,
            businessAccount,
            holderName,
          } = data || {};
          const isBusinessAccount = businessAccount;
          const isServiceActive = serviceStatus === 'active';
          const isBankSelectValidMatch =
            nameMatched && isServiceActive && valid;

          if (isBankSelectValidMatch) {
            if (isReceiverOfBusinessType && !isBusinessAccount) {
              handleOnOpenNoticePopup();
            } else if (isReceiverOfIndividualType && isBusinessAccount) {
              handleOnOpenNoticePopup();
            } else {
              handleUpdateBankAccount(currentBankSelected, {
                bankValidate: data,
              });
            }
          } else {
            if (isReceiverOfIndividualType) {
              if (isBusinessAccount && holderName) {
                handleOnOpenNoticePopup();
              } else {
                handleOnOpenBankAccountNumberPopup();
              }
            } else if (isReceiverOfBusinessType) {
              if (!isBusinessAccount && holderName) {
                handleOnOpenNoticePopup();
              } else {
                handleOnOpenBankAccountNumberPopup();
              }
            }
          }

          const newBankInformation = {
            id: currentBankSelected?.id,
            bankId: currentBankSelected?.bankId,
            [ADD_BANK_NAME]: currentBankSelected?.bankName,
            [ADD_BANK_ACCOUNT_HOLDER_NAME]: currentBankSelected?.accountName,
            [ADD_BANK_ACCOUNT_NUMBER_NAME]: currentBankSelected?.accountNumber,
            [ADD_BANK_SWIFT_NAME]: currentBankSelected?.swiftCode,
            [ADD_BANK_BSB_NAME]: currentBankSelected?.branchCode,
          };
          setBankInformation(newBankInformation);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      }
    } catch (error) {
      dispatch({ type: SET_SHOW_LOADING, payload: false });

      if (isApiEnabled) {
        handleOnOpenBankAccountNumberPopup();
      }
    }
  };

  const fetchBanksInCountry = async (countryCode) => {
    try {
      const { data } = await api.getBanks(countryCode);

      if (data) {
        setBanks(data);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };
  useEffect(() => {
    if (countryConfig) {
      fetchBanksInCountry(countryConfig?.countryCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryConfig]);

  const handleRecheckBankAccount = (id, bank) => {
    handleBankSelected(id, bank);

    const currentBank = banks?.find((b) => b.name === bank.bankName);

    handleNumberValidation(currentBank?.id, bank);
  };

  const handleUpdateBankAccountName = async (id, bankUpdate) => {
    dispatch({ type: SET_SHOW_LOADING, payload: true });

    handleBankSelected(id, bankUpdate);

    let bank = {
      id: bankUpdate?.id,
      bankId: bankUpdate?.bankId,
      bankName: bankUpdate?.bankName,
      accountName: accountHolderName,
      accountNumber: bankUpdate?.accountNumber,
      swiftCode: bankUpdate?.swiftCode,
      branchCode: bankUpdate?.branchCode,
    };

    try {
      const { data: bankUpdateRes } = await updateBankAccount(
        token,
        currentReceiver?.receiverId,
        bank
      );

      if (bankUpdateRes) {
        const newInformation = {
          ...information,
          [ADD_TRANSACTION_METHOD_BANK_NAME]: {
            ...information[ADD_TRANSACTION_METHOD_BANK_NAME],
            bank: { ...bankUpdateRes, bankId: bankUpdate?.bankId },
          },
          currentPayoutItemSelectedIndex: bankUpdateRes?.id,
        };

        fetchReceiverInformation(
          token,
          currentReceiver?.receiverId,
          newInformation,
          currentStep
        );

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    } catch (error) {
      console.error(error?.message);

      dispatch({ type: SET_SHOW_LOADING, payload: false });
    }
  };

  const handleBankSelected = (id, bank) => {
    const newInformation = {
      ...information,
      [ADD_TRANSACTION_METHOD_BANK_NAME]: {
        dmCode: METHOD_BANK,
        bank,
        pickupOffice: null,
        ewallet: null,
      },
      currentPayoutItemSelectedIndex: id,
    };

    setInformation(newInformation);

    const newValidation = {
      ...validation,
      [ADD_TRANSACTION_METHOD_BANK_NAME]: '',
    };
    setValidation(newValidation);

    setPayoutMethodItemByBANK(bank, id);
  };

  const handleSelectBankItem = (bankId, bank) => {
    if (isDisabled) return;

    const isBankAccountOfIndividualReceiverInvalid =
      isReceiverOfIndividualType && bank?.businessAccount;
    if (isBankAccountOfIndividualReceiverInvalid) {
      handleOnOpenNoticePopup(bank);
      dispatch({ type: SET_SHOW_LOADING, payload: false });
      return;
    }

    const isBankAccountOfBusinessReceiverInvalid =
      isReceiverOfBusinessType && !bank?.businessAccount;
    if (isBankAccountOfBusinessReceiverInvalid) {
      handleOnOpenNoticePopup(bank);
      dispatch({ type: SET_SHOW_LOADING, payload: false });
      return;
    }

    const currentServiceAlertsByPayoutMethodItem = transformServiceAlertsData(
      serviceAlertsByCountryCodeData?.[METHOD_BANK]?.[bank?.bankId]
    );

    if (
      !isApiEnabled &&
      accountHolder?.visible &&
      accountHolder?.required &&
      !bank?.accountName
    ) {
      if (
        currentServiceAlertsByPayoutMethodItem?.type &&
        !compareObject(
          currentServiceAlertsByPayoutMethodItem,
          serviceAlertsByPayoutMethodItem
        )
      ) {
        handleBankSelected(bankId, bank);
        saveValidateBankAccountModalIntoServiceAlertsStore({
          bank,
          showModal: () => {
            if (
              currentServiceAlertsByPayoutMethodItem?.type ===
              SERVICE_ALERTS_TYPE.DISABLE
            )
              return;
            handleUpdateBankAccountName(bankId, bank);
          },
        });
        return;
      }
      saveValidateBankAccountModalIntoServiceAlertsStore({});
      handleUpdateBankAccountName(bankId, bank);
      return;
    }

    if (
      (!bank?.isVerified && !bank?.verifyBy) ||
      (bank?.isVerified && !bank?.accountName)
    ) {
      if (
        currentServiceAlertsByPayoutMethodItem?.type &&
        !compareObject(
          currentServiceAlertsByPayoutMethodItem,
          serviceAlertsByPayoutMethodItem
        )
      ) {
        handleBankSelected(bankId, bank);
        saveValidateBankAccountModalIntoServiceAlertsStore({
          bank,
          showModal: () => {
            // if (
            //   currentServiceAlertsByPayoutMethodItem?.type ===
            //   SERVICE_ALERTS_TYPE.DISABLE
            // )
            //   return;
            handleRecheckBankAccount(bankId, bank);
          },
        });
        return;
      }
      saveValidateBankAccountModalIntoServiceAlertsStore({});
      handleRecheckBankAccount(bankId, bank);
      return;
    }

    saveValidateBankAccountModalIntoServiceAlertsStore({});
    return handleBankSelected(bankId, bank);
  };

  const getBankSelected = (currentBankId) => {
    return currentReceiver?.banks?.find((bank) => bank.id === currentBankId);
  };

  const renderAddNewBankButton = (style) => {
    if (style === 2) {
      return (
        <ButtonCommon
          value={t('button_add_new_bank_account')}
          color="var(--ds-c-blue)"
          background="var(--ds-c-white)"
          borderNameVariable="--border-filter"
          isFill={false}
          styles={{
            height: '44px',
            borderRadius: '12px',
            fontWeight: 600,
          }}
          buttonIconStyles={{
            height: '44px',
            flexDirection: 'row-reverse',
            gap: '0px',
          }}
          iconSrc={AddIcon}
          iconStyles={{
            filter: 'var(--ds-f-c-blue)',
          }}
          isReverseIcon={true}
          onClick={handleOnToggleFormAddNewBank}
        />
      );
    }

    if (isReceiverOfBusinessType) return null;

    if (
      arrayUtils.isArrayEmpty(
        receiverInformation?.banks || currentReceiver?.banks
      )
    )
      return null;

    return (
      <ButtonCommon
        value={t('button_add_new_bank_account')}
        color="var(--ds-c-blue-hyperlink-default)"
        background="var(--ds-c-white)"
        styles={{
          paddingInline: 0,
          height: '20px',
          borderRadius: 0,
          fontWeight: 500,
        }}
        buttonIconStyles={{
          height: '20px',
          flexDirection: 'row-reverse',
          gap: '6px',
          marginTop: '16px',
        }}
        iconSrc={AddCircleOutlineIcon}
        iconStyles={{
          filter:
            'invert(12%) sepia(87%) saturate(6995%) hue-rotate(234deg) brightness(90%) contrast(113%)',
        }}
        onClick={handleOnToggleFormAddNewBank}
      />
    );
  };

  const handleOnYesNoticePopup = () => {
    closeServiceAlertsModal();
    setBankInformation(initialFormBank);
    setCurrentBusinessReceiver({
      receiver: currentReceiver,
      bank: bankInformation,
    });
    if (isReceiverOfIndividualType) {
      navigate(`${RECEIVERS_ADD_SUFIX}?type=${CREATE_BUSINESS_RECEIVER_TYPE}`);
      return;
    }
    if (isReceiverOfBusinessType) {
      navigate(
        `${RECEIVERS_ADD_SUFIX}?type=${CREATE_INDIVIDUAL_RECEIVER_TYPE}`
      );
      return;
    }
  };
  const handleOnNoNoticePopup = () => {
    if (!isOpenUpdateBankPopup) {
      handleOnClickDeleteBankPopup();
      setBankInformation(initialFormBank);
    }
    setOpenNoticePopup(false);
  };

  const renderNoticePopup = (isOpen) => {
    const getContentOfNoticePopup = () => {
      if (isReceiverOfIndividualType)
        return t('popup_send_money_notice_individual_receiver', {
          holderName: bankInformation[ADD_BANK_ACCOUNT_HOLDER_NAME],
          receiverFullName: receiverFullName,
        });

      if (isReceiverOfBusinessType)
        return t('popup_send_money_notice_business_receiver', {
          holderName: bankInformation[ADD_BANK_ACCOUNT_HOLDER_NAME],
          receiverFullName: receiverFullName,
        });

      return '';
    };

    const content = getContentOfNoticePopup();

    if (content)
      return (
        <InformPopup
          isOpen={isOpen}
          imageSrc={NoticeIcon}
          imagesStyles={{
            width: '124px',
            height: '100px',
          }}
          content={content}
          isBackdropOverlap={true}
          zIndexBackdropOverlap={1055}
          buttonLabel1={t('button_no')}
          onClose={handleOnNoNoticePopup}
          buttonLabel2={t('button_yes')}
          onClick={handleOnYesNoticePopup}
          isClickBackdropToClose={false}
        />
      );

    return null;
  };

  return (
    <>
      <Wrapper>
        {bankInformation && (
          <DeleteBankAccountPopup
            isOpen={isOpenDeleteBankPopup}
            accountNumber={bankInformation[ADD_BANK_ACCOUNT_NUMBER_NAME]}
            onClose={handleOnCloseDeleteBankAccountPopup}
            onClick={handleOnClickDeleteBankPopup}
          />
        )}

        {isOpenUpdatePopup && (
          <InformPopup
            isOpen={isOpenUpdatePopup}
            text={t('popup_update_success_title')}
            content={stringUtils.replaceKeyword(
              t('popup_update_success_desc'),
              [
                {
                  key: 'label',
                  value: t('label_bank_account'),
                },
              ]
            )}
            buttonLabel2={t('button_close')}
            onClick={handleOnToggleUpdatePopup}
          />
        )}

        {isOpenBankNumberAccountPopup && (
          <BankNumberAccountPopup
            isOpen={isOpenBankNumberAccountPopup}
            holderName={bankInformationRecheck?.bankValidate?.holderName}
            onAccept={() =>
              handleUpdateBankAccount(
                getBankSelected(currentPayoutItemSelectedIndex),
                bankInformationRecheck
              )
            }
            onEdit={() =>
              handleOnOpenFormUpdateBankAccountPopup(
                getBankSelected(currentPayoutItemSelectedIndex)
              )
            }
          />
        )}

        {isOpenNoticePopup && renderNoticePopup(isOpenNoticePopup)}

        {isOpenFormAddNewBankPopup && (
          <FormAddNewBankAccount
            isOpen={isOpenFormAddNewBankPopup}
            countryConfig={countryConfig}
            currentReceiver={currentReceiver}
            information={receiverInformation}
            setInformation={setReceiverInformation}
            bankInformation={bankInformation}
            setBankInformation={setBankInformation}
            bankValidation={bankValidation}
            setBankValidation={setBankValidation}
            onClose={handleOnToggleFormAddNewBank}
            onClick={handleOnClickFormAddNewBank}
          />
        )}

        {isOpenUpdateBankPopup && (
          <FormUpdateBankAccount
            isOpen={isOpenUpdateBankPopup}
            isEdit={isOpenUpdateBankPopup}
            countryConfig={countryConfig}
            currentReceiver={currentReceiver}
            information={receiverInformation}
            setInformation={setReceiverInformation}
            bankInformation={bankInformation}
            setBankInformation={setBankInformation}
            bankValidation={bankValidation}
            setBankValidation={setBankValidation}
            onClose={handleOnCloseFormUpdateBankAccountPopup}
            onClick={handleUpdateBankAccount}
          />
        )}

        {arrayUtils.isArrayEmpty(
          receiverInformation?.banks || currentReceiver?.banks
        ) ? (
          <>
            <NoBank $isRequired={validation[ADD_TRANSACTION_METHOD_BANK_NAME]}>
              {t('label_no_bank_account')}
              {renderAddNewBankButton(2)}
            </NoBank>
          </>
        ) : (
          <>
            {(receiverInformation?.banks || currentReceiver?.banks)?.map(
              (bank) => (
                <BankPayoutInformationStyled
                  key={bank?.id}
                  $isActive={
                    !checkServiceAlertsOfPayoutMethodItemItemDisable({
                      serviceAlertsStore,
                      payoutItemId: bank?.bankId,
                    }) && isIdSelected(currentPayoutItemSelectedIndex, bank?.id)
                  }
                  $isDisabled={isDisabled}
                >
                  <GridItem
                    $isDisabled={isDisabled}
                    onClick={() => handleSelectBankItem(bank?.id, bank)}
                  >
                    <FormInputRadioCheckbox
                      isChecked={
                        !checkServiceAlertsOfPayoutMethodItemItemDisable({
                          serviceAlertsStore,
                          payoutItemId: bank?.bankId,
                        }) &&
                        isIdSelected(currentPayoutItemSelectedIndex, bank?.id)
                      }
                    />
                  </GridItem>
                  <GridItem
                    style={{ position: 'relative', justifyContent: 'start' }}
                    $isDisabled={isDisabled}
                    onClick={() => handleSelectBankItem(bank?.id, bank)}
                  >
                    <Text style={{ marginBottom: '4px' }}>
                      {bank?.bankName}
                    </Text>
                    {accountHolder?.visible && (
                      <SubText>
                        {t('label_bank_account_holder')}: {bank?.accountName}
                      </SubText>
                    )}
                    {accountNumber?.visible && (
                      <SubText>
                        {t('label_bank_account_number')}:{' '}
                        {bank?.accountNumber
                          ? formatter.mask(
                              getNumberUnmaskStr(
                                bank?.accountNumber,
                                accountNumberFormat
                              ),
                              accountNumberFormat
                            )
                          : ''}
                      </SubText>
                    )}
                    {accountSwift?.visible && (
                      <SubText>SWIFT: {bank?.swiftCode}</SubText>
                    )}
                    {accountBsb?.visible && (
                      <SubText>
                        {accountBsb?.type || 'BSB'}:{' '}
                        {bank?.branchCode
                          ? formatter.mask(
                              getNumberUnmaskStr(
                                bank?.branchCode,
                                bsbNumberFormat
                              ),
                              bsbNumberFormat
                            )
                          : ''}
                      </SubText>
                    )}
                  </GridItem>
                  {!isDisabled && (
                    <GridItem>
                      <CustomActionDropdown
                        styles={{
                          height: '24px',
                          width: '24px',
                          background: 'transparent',
                          position: 'absolute',
                          right: isDesktop ? '-16px' : '-32px',
                        }}
                        dropdownMenuStyles={{
                          transform: 'translate(18px,32px) !important',
                        }}
                        onEdit={() =>
                          handleOnOpenFormUpdateBankAccountPopup(bank)
                        }
                        onDelete={() =>
                          handleOnOpenDeleteBankAccountPopup(bank)
                        }
                      />
                    </GridItem>
                  )}
                </BankPayoutInformationStyled>
              )
            )}
          </>
        )}
      </Wrapper>
      {!isDisabled && renderAddNewBankButton()}
    </>
  );
};

const Wrapper = styled.div`
  background: var(--ds-bg-1);
  border-radius: 12px;
  padding: 8px;
`;

const BankPayoutInformationStyled = styled.div`
  display: grid;
  grid-template-columns: 50px calc(100% - 100px) 50px;
  grid-gap: 0px;
  width: 100%;
  height: fit-content;
  padding: 10px;
  margin-bottom: 0px;
  border-bottom: 1px solid var(--ds-c-blue-remox-light);
  background-color: transparent;

  ${(props) =>
    props.$isDisabled &&
    `
    background-color: #eeeeee;
    color: #7a8189;
  `};

  &:last-child {
    border-bottom: none;
  }
`;
const GridItem = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: start;

  &:nth-child(1) {
    justify-content: center;
    align-items: center;
  }

  &:last-child {
    justify-content: space-around;
    align-items: start;
  }

  & div {
    & div,
    input {
      cursor: ${(props) => props.$isDisabled && 'not-allowed'};
    }
  }

  & p {
    cursor: ${(props) => props.$isDisabled && 'not-allowed'};
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;
  text-align: left;
  text-transform: uppercase;

  margin: 0;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;
  text-align: left;

  margin: 0;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const NoBank = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${(props) =>
    props.$isRequired ? 'var(--ds-c-red)' : 'var(--ds-c-grey-dark)'};

  display: grid;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: calc(100% + 16px);
  height: fit-content;
  background: #ffffff;
  border: ${(props) =>
    props.$isRequired
      ? '1px dashed var(--ds-c-red)'
      : '1px dashed var(--ds-bg-2)'};
  border-radius: 12px;
  margin-top: -8px;
  margin-bottom: -8px;
  margin-left: -8px;
  margin-right: -8px;
  padding-block: 28px;
`;

export default BankPayoutInformation;
