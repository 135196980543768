import { LogUtil } from 'components/common/LogUtil';
import useRetriveDatabaseInstance from 'hooks/useRetriveDatabaseInstance';
import {
  SET_SERVICE_ALERTS_BY_COUNTRY_CODE,
  SET_SERVICE_ALERTS_BY_PAYMENTS,
} from 'store/action';
import { ServiceAlertsModel } from '../model/ServiceAlertsModel';

const TAG = 'service-alerts-observer';

const actionNameVariant: any = {
  countryCode: SET_SERVICE_ALERTS_BY_COUNTRY_CODE,
  payments: SET_SERVICE_ALERTS_BY_PAYMENTS,
};

interface Props {
  variant: string;
  dbPath: string;
}
const useServiceAlertsObserver = ({ variant, dbPath }: Props) => {
  const {
    retriveDataFromRealtimeDatabase: onChangeNewestServiceAlertsListener,
    closeRealtimeDatabaseConnection: onCloseServiceAlertsListener,
  } = useRetriveDatabaseInstance({
    databaseURL: process.env.REACT_APP_FIREBASE_SERVICE_ALERTS_DATABASE_URL,
    path: dbPath,
    actionName: actionNameVariant[variant],
    isDisable: true,
  });

  const fetchServiceAlertsListener = async ({
    path,
    setServiceAlerts,
  }: {
    path: string;
    setServiceAlerts: (data: any) => void;
  }) => {
    try {
      onChangeNewestServiceAlertsListener({
        path,
        callback: (data?: ServiceAlertsModel) => {
          if (data) {
            // LogUtil.log(
            //   TAG,
            //   `onChangeNewestServiceAlertsListener xxxx ${variant}`
            // );
            setServiceAlerts(data);
          }
        },
      });
    } catch (error) {
      LogUtil.error(
        TAG,
        `onChangeNewestServiceAlertsListener xxxx error xxxx ${variant}`,
        JSON.stringify(error)
      );
    }
  };

  const closeServiceAlertsListener = ({
    path,
    callback = () => {},
  }: {
    path?: string | any;
    callback?: (() => void) | any;
  }) => {
    onCloseServiceAlertsListener({
      path,
      callback,
    });
  };

  return { fetchServiceAlertsListener, closeServiceAlertsListener };
};

export default useServiceAlertsObserver;
