import useCheckServiceAlerts from '../useCheckServiceAlerts';
import useServiceAlertsListener from './useServiceAlertsListener';

const useServiceAlertsByPaymentsListener = () => {
  const { checkByPaymentMethod } = useCheckServiceAlerts();
  const { resetPaymentMethodSelected } = checkByPaymentMethod;

  const { onListener: paymentMethodListener } = useServiceAlertsListener({
    variant: 'paymentMethod',
    modalOnReset: resetPaymentMethodSelected,
  });

  return { paymentMethodListener };
};

export default useServiceAlertsByPaymentsListener;
