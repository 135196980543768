import useServiceAlertsListener from '../listeners/useServiceAlertsListener';
import useCheckServiceAlerts from '../useCheckServiceAlerts';
import useServiceAlertsStore from '../useServiceAlertsStore';

const useServiceAlertsByCountryCodeListener = () => {
  const { serviceAlertsByCountryCode: serviceAlertsStore } =
    useServiceAlertsStore();
  const { showReceiverMissingInformationModal, showValidateBankAccountModal } =
    serviceAlertsStore || {};

  const {
    checkByCountryCode,
    checkByPayoutMethod,
    checkByPayoutMethodItem,
    checkByCurrencyCode,
    checkByRangeOfCurrencyCode,
  } = useCheckServiceAlerts();
  const { resetReceiverSelected } = checkByCountryCode;
  const { resetPayoutMethodSelected } = checkByPayoutMethod;
  const { resetPayoutMethodItemSelected } = checkByPayoutMethodItem;
  const { resetCurrencyCodeSelected: resetCurrencyCodeSelected1 } =
    checkByCurrencyCode('currencyCode1');
  const { resetCurrencyCodeSelected: resetCurrencyCodeSelected2 } =
    checkByCurrencyCode('currencyCode2');
  const { resetRangeOfCurrencyCode: resetRangeOfCurrencyCode1 } =
    checkByRangeOfCurrencyCode('rangeOfCurrencyCode1');
  const { resetRangeOfCurrencyCode: resetRangeOfCurrencyCode2 } =
    checkByRangeOfCurrencyCode('rangeOfCurrencyCode2');

  const { onListener: countryCodeListener } = useServiceAlertsListener({
    variant: 'countryCode',
    modalOnReset: resetReceiverSelected,
  });

  const { onListener: payoutMethodListener } = useServiceAlertsListener({
    variant: 'payoutMethod',
    modalOnReset: resetPayoutMethodSelected,
    modalOnClick: showReceiverMissingInformationModal,
  });

  const { onListener: payoutMethodItemListener } = useServiceAlertsListener({
    variant: 'payoutMethodItem',
    modalOnReset: resetPayoutMethodItemSelected,
    modalOnClick: showValidateBankAccountModal,
  });

  const { onListener: payoutMethodItemChildListener } =
    useServiceAlertsListener({
      variant: 'payoutMethodItemChild',
      modalOnReset: resetPayoutMethodItemSelected,
      modalOnClick: showValidateBankAccountModal,
    });

  const { onListener: currencyCode1Listener } = useServiceAlertsListener({
    variant: 'currencyCode1',
    modalOnReset: resetCurrencyCodeSelected1,
  });

  const { onListener: currencyCode2Listener } = useServiceAlertsListener({
    variant: 'currencyCode2',
    modalOnReset: resetCurrencyCodeSelected2,
  });

  const { onListener: rangeOfCurrencyCode1Listener } = useServiceAlertsListener(
    {
      variant: 'rangeOfCurrencyCode1',
      modalOnReset: resetRangeOfCurrencyCode1,
    }
  );

  const { onListener: rangeOfCurrencyCode2Listener } = useServiceAlertsListener(
    {
      variant: 'rangeOfCurrencyCode2',
      modalOnReset: resetRangeOfCurrencyCode2,
    }
  );

  return {
    countryCodeListener,
    payoutMethodListener,
    payoutMethodItemListener,
    payoutMethodItemChildListener,
    currencyCode1Listener,
    currencyCode2Listener,
    rangeOfCurrencyCode1Listener,
    rangeOfCurrencyCode2Listener,
  };
};

export default useServiceAlertsByCountryCodeListener;
