import { LogUtil } from 'components/common/LogUtil';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { compareServiceAlertsStore } from '../helpers/compares';
import useServiceAlertsByPaymentsListener from '../listeners/useServiceAlertsByPaymentsListener';
import { ServiceAlertsStoreModal } from '../model/ServiceAlertsLocalStore';
import { ServiceAlertsModel } from '../model/ServiceAlertsModel';
import useServiceAlertsConfig from '../useServiceAlertsConfig';
import useServiceAlertsStore from '../useServiceAlertsStore';
import useServiceAlertsObserver from './useServiceAlertsObserver';

const TAG = 'service-alerts-payment-observer';

interface Props {
  dbPath?: string;
  variant: string;
}
const useServiceAlertsByPaymentObserver = ({ dbPath, variant }: Props) => {
  const isPreventObserver = false;

  const {
    serviceAlertsByPayments: serviceAlertsStore,
    setServiceAlertsByPayments: setServiceAlerts,
  } = useServiceAlertsStore();

  const { countryCode, paymentMethod } = useServiceAlertsConfig();

  const [serviceAlertsByPaymentData, setServiceAlertsByPaymentData] =
    useState<ServiceAlertsModel | null>(null);

  const { fetchServiceAlertsListener, closeServiceAlertsListener } =
    useServiceAlertsObserver({
      variant,
      dbPath: dbPath || '',
    });

  useEffect(() => {
    const isAllowConnectToRealtimeDb =
      !isPreventObserver && countryCode && dbPath;

    if (isAllowConnectToRealtimeDb) {
      fetchServiceAlertsListener({
        path: dbPath,
        setServiceAlerts: setServiceAlertsByPaymentData,
      });
    }

    return () => {
      if (isAllowConnectToRealtimeDb) {
        closeServiceAlertsListener({
          path: dbPath,
        });

        setServiceAlertsByPaymentData(null);
      }
    };
  }, [isPreventObserver, countryCode, dbPath]);

  const { paymentMethodListener } = useServiceAlertsByPaymentsListener();
  useEffect(() => {
    if (!isPreventObserver && !isEmpty(serviceAlertsByPaymentData)) {
      const serviceAlertsByPayment = serviceAlertsByPaymentData;
      const serviceAlertsByPaymentMethod =
        serviceAlertsByPayment?.[paymentMethod || ''];

      let newServiceAlertsStore: ServiceAlertsStoreModal | any = {
        ...serviceAlertsStore,
        serviceAlertsByPaymentData,
        ...(!paymentMethod && { serviceAlertsByPaymentMethod: null }),
      };

      if (paymentMethod) {
        newServiceAlertsStore = paymentMethodListener({
          data: serviceAlertsByPaymentMethod,
          serviceAlertsStore: newServiceAlertsStore,
        });
      }

      const isServiceAlertsStoreChange = !compareServiceAlertsStore(
        serviceAlertsStore,
        newServiceAlertsStore
      );
      if (isServiceAlertsStoreChange) {
        LogUtil.log(
          TAG,
          `serviceAlertsStore xxxx data xxxx ${variant}`,
          JSON.stringify(newServiceAlertsStore)
        );
        setServiceAlerts(newServiceAlertsStore);
      }
    }
  }, [
    isPreventObserver,
    serviceAlertsByPaymentData,
    serviceAlertsStore,
    paymentMethod,
  ]);
};

export default useServiceAlertsByPaymentObserver;
