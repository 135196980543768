/* eslint-disable no-unused-vars */
import { api } from 'api';
import { RESET_ADD_TRANSACTION_INFORMATION_STORE } from 'constants';
import { ADD_TRANSACTION_RECEIVER_NAME } from 'constants';
import {
  PAGE_DEFAULT,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
  SIZE_PAGE_LIMIT_DEFAULT,
} from 'constants';
import { isArray } from 'lodash';
import { dispatchStore } from 'store';
import {
  SET_ADD_TRANSACTION_INFORMATION,
  SET_CURRENT_RECEIVER,
  SET_EDIT_RECEIVER_INFORMATION,
  SET_SHOW_LOADING,
} from 'store/action';
import { arrayUtils, objectUtils } from 'utils';

export const convertFilteredKeyFixed = (filtered = {}) => {
  const filteredFixed = {
    countriesSelected: filtered?.countriesSelected,
    favoriteSelected: filtered?.favoriteSelected,
  };

  return objectUtils.deletePropIsFalsy(filteredFixed);
};

export const fetchReceiversCountries = async (
  token,
  setCountries,
  isReceiverOfBusinessType = false
) => {
  try {
    const path = isReceiverOfBusinessType ? '/business' : '';

    const { data } = await api.getReceiversCountries(token, path);

    if (data) {
      setCountries && setCountries(data);
    }
  } catch (error) {
    console.error(error?.message);
  }
};

export const fetchReceiversFavorite = async ({ token, setReceivers }) => {
  try {
    const { data } = await api.getFavReceivers(token);

    if (data?.content?.length) {
      setReceivers && setReceivers(data?.content);
    }
  } catch (error) {
    console.error(error?.message);
  }
};

export const getBodyPayloadFiltered = (filtered) => {
  return {
    countryCode: filtered?.countriesSelected?.map((country) => country.code),
    favorited: filtered?.favoriteSelected,
  };
};

export const fetchReceiversFiltered = async ({
  token = null,
  body = null,
  sort = null,
  page = PAGE_DEFAULT,
  size = SIZE_PAGE_LIMIT_DEFAULT,
  receivers = null,
  setReceivers = null,
  setCurrentPage = null,
  setTotalPages = null,
  setTotalElements = null,
  isDelete = false,
  setFetching = null,
}) => {
  if (token) {
    try {
      const sortPayload = sort || {};
      let bodyPayload = body || {};

      bodyPayload = objectUtils.deletePropIsFalsy(bodyPayload);

      if (bodyPayload['favorited'] === 'all') {
        delete bodyPayload['favorited'];
      }

      const { data } = await api.searchReceivers(
        token,
        bodyPayload,
        sortPayload,
        page,
        size
      );

      if (data) {
        const { content, totalPages, totalElements } = data;

        if (receivers?.length || (receivers && isDelete)) {
          setReceivers && setReceivers([...receivers, ...content]);
        } else if (!arrayUtils.isArrayEmpty(content)) {
          setReceivers && setReceivers(content);
        } else if (arrayUtils.isArrayEmpty(content)) {
          if (isArray(receivers)) {
            setReceivers([]);
          } else {
            setReceivers(null);
          }
        }

        setCurrentPage && setCurrentPage(page);

        setTotalPages && setTotalPages(totalPages);

        setTotalElements && setTotalElements(totalElements);

        setFetching && setFetching(false);
      }
    } catch (error) {
      console.error(error?.message);
    }
  }
};

export const fetchReceiverInformation = async (token, receiverId) => {
  try {
    const { data: dataReceiver } = await api.getReceiver(token, receiverId);

    if (dataReceiver) {
      const {
        firstname,
        lastname,
        gender,
        mobile,
        phone,
        address,
        banks,
        ewallets,
        favorited,
        relationship,
      } = dataReceiver;

      const newInformation = {
        receiverId,
        firstName: firstname,
        lastName: lastname,
        country: address?.country,
        countryName: address?.country?.name,
        gender,
        address,
        fullAddress: address?.fullAddress,
        mobile,
        mobileNumber: mobile?.number,
        phone,
        phoneNumber: phone?.number,
        banks,
        ewallets,
        favorited,
        relationship,
      };

      dispatchStore({ type: SET_CURRENT_RECEIVER, payload: newInformation });

      dispatchStore({
        type: SET_ADD_TRANSACTION_INFORMATION,
        payload: {
          ...RESET_ADD_TRANSACTION_INFORMATION_STORE,
          receiverPayoutMethod: {
            ...RESET_ADD_TRANSACTION_INFORMATION_STORE.receiverPayoutMethod,
            [ADD_TRANSACTION_RECEIVER_NAME]: newInformation,
          },
        },
      });

      dispatchStore({
        type: SET_EDIT_RECEIVER_INFORMATION,
        payload: {
          ...RESET_EDIT_RECEIVER_INFORMATION_STORE,
          residentialAddress: {
            ...RESET_EDIT_RECEIVER_INFORMATION_STORE.residentialAddress,
            isEditReceiverEnterAddressManuallySelected: address.manual,
          },
        },
      });

      return true;
    }
  } catch (error) {
    console.error(error?.message);

    return false;
  }
};

export const fetchAllReceivers = async ({ token, setReceivers }) => {
  if (token) {
    try {
      const { data } = await api.getAllReceivers(token);

      if (data) {
        setReceivers(data);
      }

      dispatchStore({
        type: SET_SHOW_LOADING,
        payload: false,
      });
    } catch (error) {
      console.error(error?.message);
      dispatchStore({
        type: SET_SHOW_LOADING,
        payload: false,
      });
    }
  }
};

export const addNewBankAccount = async (token, receiverId, bank) => {
  delete bank['id'];

  bank = {
    ...bank,
    accountName: bank?.accountName?.toUpperCase(),
  };

  return api.addBank(token, receiverId, bank);
};
export const updateBankAccount = async (token, receiverId, bank) => {
  return api.updateBank(token, receiverId, bank.id, bank);
};
export const deleteBankAccount = async (token, receiverId, bankId) => {
  return api.deleteBank(token, receiverId, bankId);
};

export const addNewEwalletAccount = async (token, receiverId, ewallet) => {
  delete ewallet['id'];

  return api.addEwallet(token, receiverId, ewallet);
};
export const updateEwalletAccount = async (token, receiverId, ewallet) => {
  return api.updateEwallet(token, receiverId, ewallet.id, ewallet);
};
export const deleteEwalletAccount = async (token, receiverId, ewalletId) => {
  return api.deleteEwallet(token, receiverId, ewalletId);
};
