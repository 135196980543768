/* eslint-disable no-unused-vars */
import { api } from 'api';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  checkFormInputValidation,
  fetchCountries,
} from 'components/common/func';
import FormInputGenderRadio from 'components/FormEditReceiver/components/FormInputGenderRadio';
import FormInputValidation from 'components/FormEditReceiver/components/FormInputValidation';
import {
  EDIT_RECEIVER_COUNTRY_FIELD,
  EDIT_RECEIVER_COUNTRY_NAME,
  EDIT_RECEIVER_GENDER_NAME,
  EDIT_RECEIVER_GENERAL_DETAILS_NAME_FIELDS,
  EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS,
  EDIT_RECEIVER_MOBILE_NAME,
  EDIT_RECEIVER_PERSONAL_DETAILS_GENDER_FIELD,
  EDIT_RECEIVER_PHONE_NAME,
  EDIT_RECEIVER_RELATIONSHIP_FIELD,
  EDIT_RECEIVER_RELATIONSHIP_NAME,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
} from 'constants';
import { ADD_RECEIVER_STEP_REFERENCE } from 'constants/form-add-receiver';
import { format } from 'helpers';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { SET_ADD_RECEIVER_INFORMATION } from 'store/action';
import styled from 'styled-components';

import CustomSearchDropdown from 'components/common/CustomSearchDropdown';
import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import FormInputMobileOrPhoneNumberValidation from 'components/FormEditReceiver/components/FormInputMobileOrPhoneNumberValidation';
import FormInputNameValidation from 'components/FormEditReceiver/components/FormInputNameValidation';
import FormInputSelectTypeOfReceiverRadio from 'components/FormEditReceiver/components/FormInputSelectTypeOfReceiverRadio';
import {
  ADD_BANK_ACCOUNT_HOLDER_NAME,
  BUSINESS_OF_RECEIVER_TYPE,
  DESKTOP_MIN_WIDTH,
  EDIT_RECEIVER_BUSINESS_NAME_FIELD,
  EDIT_RECEIVER_BUSINESS_PHONE_FIELD,
  EDIT_RECEIVER_FISTNAME_NAME,
  EDIT_RECEIVER_FULLNAME_NAME,
  EDIT_RECEIVER_LASTNAME_NAME,
  EDIT_RECEIVER_PERSONAL_DETAILS_SELECT_TYPE_OF_RECEIVER_FIELD,
  EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME,
  PROPERTIES_NOT_IN_BUSINESS_RECEIVER_TYPE,
  RECEIVERS_ADD_SUFIX,
} from 'constants';
import useFormAddReceiverStore from 'hooks/form-add-receiver/useFormAddReceiverStore';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import useReceiver from 'hooks/receiver/useReceiver';
import { useDetectDevice } from 'hooks/useDetectDevice';
import usePortalModal from 'hooks/usePortalModal';
import { isEmpty } from 'lodash';
import { Fade } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BodySectionHeader from '../../BodySectionHeader';

const initialFormField = RESET_EDIT_RECEIVER_INFORMATION_STORE.personalDetails;

const PersonalDetails = ({
  countryConfig,
  setCurrentCountryConfig,
  currentStep,
  setCurrentStep,
  handleOnToggleCancelPopup,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const {
    editReceiverInformation,
    configRelationship,
    configRelationshipOfBusiness,
  } = state;
  const { personalDetails } = editReceiverInformation || {};
  const { isMobile, isTablet } = useDetectDevice();
  const { currentBusinessReceiver, resetCurrentBusinessReceiver } =
    useFormAddTransactionStore();
  const { resetAddReceiverInformation } = useFormAddReceiverStore();
  const { showPortalModalName } = usePortalModal();
  const navigate = useNavigate();

  const config = useGetConfig({ countryConfig });
  const { mobile, phone, relationship } = config;

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [countries, setCountries] = useState(null);
  const [eitherRequiredMsg, setEitherRequiredMsg] = useState('');
  const [mobileMaskStr, setMobileMaskStr] = useState('');
  const [phoneMaskStr, setPhoneMaskStr] = useState('');

  const { isCreateBusinessReceiver, isCreateIndividualReceiver } =
    useReceiver();

  const isShowEitherRequiredMsg =
    eitherRequiredMsg &&
    !validation[EDIT_RECEIVER_MOBILE_NAME] &&
    !validation[EDIT_RECEIVER_PHONE_NAME];

  const isVietNamCountry =
    information[EDIT_RECEIVER_COUNTRY_NAME]?.name === 'VIETNAM';

  const isReceiverOfIndividualType =
    information[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME] === 1;
  const isReceiverOfBusinessType =
    information[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME] === 2;

  const fetchCountryConfig = async (countryCode) => {
    try {
      const { data } = await api.getCountryConfig(countryCode);

      setCurrentCountryConfig(data);
    } catch (error) {
      console.error(error?.message);
    }
  };
  useEffect(() => {
    const countrySelected = countries?.find(
      (country) =>
        country?.name === information[EDIT_RECEIVER_COUNTRY_NAME]?.name
    );

    if (countrySelected) {
      fetchCountryConfig(countrySelected.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information[EDIT_RECEIVER_COUNTRY_NAME], countries]);

  useEffect(() => {
    fetchCountries((data) => {
      setCountries(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const informationStore = personalDetails;

    const newInformation = {
      ...informationStore,
      [EDIT_RECEIVER_MOBILE_NAME]: informationStore[EDIT_RECEIVER_MOBILE_NAME]
        ? format.toMobileNumberStr(informationStore[EDIT_RECEIVER_MOBILE_NAME])
        : '',
      [EDIT_RECEIVER_PHONE_NAME]: informationStore[EDIT_RECEIVER_PHONE_NAME]
        ? format.toMobileNumberStr2(informationStore[EDIT_RECEIVER_PHONE_NAME])
        : '',
    };

    setInformation(newInformation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isCreateBusinessReceiver &&
      !isEmpty(currentBusinessReceiver) &&
      countries
    ) {
      const { receiver, bank } = currentBusinessReceiver || {};

      const currentCountry = countries?.find(
        (country) => country.name === receiver?.countryName
      );

      const newInformation = {
        ...initialFormField,
        ...information,
        [EDIT_RECEIVER_COUNTRY_NAME]: currentCountry,
        [EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME]: 2,
        [EDIT_RECEIVER_FISTNAME_NAME]:
          personalDetails?.[EDIT_RECEIVER_FISTNAME_NAME] ||
          bank?.[ADD_BANK_ACCOUNT_HOLDER_NAME],
        [EDIT_RECEIVER_FULLNAME_NAME]:
          personalDetails?.[EDIT_RECEIVER_FULLNAME_NAME] ||
          bank?.[ADD_BANK_ACCOUNT_HOLDER_NAME],
        [EDIT_RECEIVER_GENDER_NAME]: BUSINESS_OF_RECEIVER_TYPE,
      };
      setInformation(newInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateBusinessReceiver, currentBusinessReceiver, countries]);
  useEffect(() => {
    if (
      isCreateIndividualReceiver &&
      !isEmpty(currentBusinessReceiver) &&
      countries
    ) {
      const { receiver, bank } = currentBusinessReceiver || {};

      const currentCountry = countries?.find(
        (country) => country.name === receiver?.countryName
      );

      const newInformation = {
        ...initialFormField,
        ...information,
        [EDIT_RECEIVER_COUNTRY_NAME]: currentCountry,
        [EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME]: 1,
        [EDIT_RECEIVER_FISTNAME_NAME]:
          personalDetails?.[EDIT_RECEIVER_FISTNAME_NAME] ||
          bank?.[ADD_BANK_ACCOUNT_HOLDER_NAME],
        [EDIT_RECEIVER_LASTNAME_NAME]:
          personalDetails?.[EDIT_RECEIVER_LASTNAME_NAME] || '',
        [EDIT_RECEIVER_FULLNAME_NAME]:
          personalDetails?.[EDIT_RECEIVER_FULLNAME_NAME] ||
          bank?.[ADD_BANK_ACCOUNT_HOLDER_NAME],
        [EDIT_RECEIVER_GENDER_NAME]: '',
      };
      setInformation(newInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateIndividualReceiver, currentBusinessReceiver, countries]);

  const onReset = (options) => {
    const { isRedirect } = options || {};

    setValidation(initialFormField);
    setEitherRequiredMsg('');
    setMobileMaskStr('');
    setPhoneMaskStr('');
    resetAddReceiverInformation();
    resetCurrentBusinessReceiver();
    isRedirect && navigate(RECEIVERS_ADD_SUFIX);
  };
  const handleReceiverOnChange = (countrySelected) => {
    setInformation({
      ...initialFormField,
      [EDIT_RECEIVER_COUNTRY_NAME]: countrySelected,
    });
    onReset();
  };
  const handleTypeOfReceiverOnChange = (typeOfReceiverSelected) => {
    setInformation({
      ...initialFormField,
      [EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME]: typeOfReceiverSelected,
    });
    onReset();
  };

  const handleNextStep = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    if (isReceiverOfBusinessType) {
      PROPERTIES_NOT_IN_BUSINESS_RECEIVER_TYPE.forEach((prop) => {
        delete newInformation[prop];
        delete newValidation[prop];
      });
    }

    if (mobile?.eitherRequired) {
      if (
        information[EDIT_RECEIVER_PHONE_NAME] &&
        !validation[EDIT_RECEIVER_MOBILE_NAME]
      ) {
        delete newInformation[EDIT_RECEIVER_MOBILE_NAME];
        delete newValidation[EDIT_RECEIVER_MOBILE_NAME];

        setEitherRequiredMsg('');
      }

      if (
        information[EDIT_RECEIVER_MOBILE_NAME] &&
        !validation[EDIT_RECEIVER_PHONE_NAME]
      ) {
        delete newInformation[EDIT_RECEIVER_PHONE_NAME];
        delete newValidation[EDIT_RECEIVER_PHONE_NAME];

        setEitherRequiredMsg('');
      }
    } else {
      if (!mobile?.visible) {
        delete newInformation[EDIT_RECEIVER_MOBILE_NAME];
        delete newValidation[EDIT_RECEIVER_MOBILE_NAME];
      }

      if (!phone?.visible) {
        delete newInformation[EDIT_RECEIVER_PHONE_NAME];
        delete newValidation[EDIT_RECEIVER_PHONE_NAME];
      }

      if (
        !phone?.required &&
        phone?.visible &&
        !validation[EDIT_RECEIVER_PHONE_NAME]
      ) {
        delete newInformation[EDIT_RECEIVER_PHONE_NAME];
        delete newValidation[EDIT_RECEIVER_PHONE_NAME];
      }

      if (
        !mobile?.required &&
        mobile?.visible &&
        !validation[EDIT_RECEIVER_MOBILE_NAME]
      ) {
        delete newInformation[EDIT_RECEIVER_MOBILE_NAME];
        delete newValidation[EDIT_RECEIVER_MOBILE_NAME];
      }
    }

    if (!relationship?.required) {
      delete newInformation[EDIT_RECEIVER_RELATIONSHIP_NAME];
      delete newValidation[EDIT_RECEIVER_RELATIONSHIP_NAME];
    }

    const isAllowNext =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value) &&
      !eitherRequiredMsg;

    if (isAllowNext) {
      dispatch({
        type: SET_ADD_RECEIVER_INFORMATION,
        payload: {
          [ADD_RECEIVER_STEP_REFERENCE[currentStep]]: information,
        },
      });

      setCurrentStep(currentStep + 1);
    }

    if (!isAllowNext) {
      newValidation = checkFormInputValidation(
        information[EDIT_RECEIVER_COUNTRY_NAME]?.name,
        EDIT_RECEIVER_COUNTRY_FIELD,
        newValidation
      );

      newValidation = checkFormInputValidation(
        information[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME]?.toString(),
        EDIT_RECEIVER_PERSONAL_DETAILS_SELECT_TYPE_OF_RECEIVER_FIELD,
        newValidation
      );

      if (isReceiverOfIndividualType) {
        EDIT_RECEIVER_GENERAL_DETAILS_NAME_FIELDS.forEach((field) => {
          newValidation = checkFormInputValidation(
            information[field.name],
            field,
            newValidation
          );
        });
      } else if (isReceiverOfBusinessType) {
        newValidation = checkFormInputValidation(
          information[EDIT_RECEIVER_FISTNAME_NAME],
          EDIT_RECEIVER_BUSINESS_NAME_FIELD,
          newValidation
        );
      }

      newValidation = checkFormInputValidation(
        information[EDIT_RECEIVER_GENDER_NAME],
        EDIT_RECEIVER_PERSONAL_DETAILS_GENDER_FIELD,
        newValidation
      );

      if (isReceiverOfIndividualType) {
        if (mobile?.eitherRequired) {
          if (
            !information[EDIT_RECEIVER_MOBILE_NAME] &&
            !information[EDIT_RECEIVER_PHONE_NAME]
          ) {
            setEitherRequiredMsg('form_mobile_phone_invalid_1');
          } else {
            setEitherRequiredMsg('');
          }
        } else {
          if (mobile?.required && !newValidation[EDIT_RECEIVER_MOBILE_NAME]) {
            newValidation = checkFormInputValidation(
              information[EDIT_RECEIVER_MOBILE_NAME],
              EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS[0],
              newValidation
            );
          }

          if (phone?.required && !newValidation[EDIT_RECEIVER_PHONE_NAME]) {
            newValidation = checkFormInputValidation(
              information[EDIT_RECEIVER_PHONE_NAME],
              EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS[1],
              newValidation
            );
          }
        }
      } else if (isReceiverOfBusinessType) {
        if (phone?.required && !newValidation[EDIT_RECEIVER_PHONE_NAME]) {
          newValidation = checkFormInputValidation(
            information[EDIT_RECEIVER_PHONE_NAME],
            EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS[1],
            newValidation
          );
        }
      }

      if (relationship?.required) {
        newValidation = checkFormInputValidation(
          information[EDIT_RECEIVER_RELATIONSHIP_NAME]?.key,
          EDIT_RECEIVER_RELATIONSHIP_FIELD,
          newValidation
        );
      }

      setValidation(newValidation);
    }
  };

  const handleSendMoneyEditReceiverCountryOnClick =
    isCreateBusinessReceiver || isCreateIndividualReceiver
      ? (countrySelected) => {
          const isCountryChooseAgain =
            information[EDIT_RECEIVER_COUNTRY_NAME]?.code ===
            countrySelected?.code;
          if (isCountryChooseAgain) {
            return;
          }

          const message = t('popup_change_receiver_country_warning');
          showPortalModalName({
            name: PORTAL_NAME_MODAL_DEFINE.CREATE_RECEIVER_MODAL,
            title: '',
            content: message,
            action: () => {
              const newInformation = {
                ...initialFormField,
                [EDIT_RECEIVER_COUNTRY_NAME]: countrySelected || null,
              };
              setInformation(newInformation);
              onReset({ isRedirect: true });
            },
            actionClose: () => {
              setInformation(information);
            },
          });
        }
      : (countrySelected) => handleReceiverOnChange(countrySelected);

  const handleSendMoneyEditReceiverTypeOnClick =
    isCreateBusinessReceiver || isCreateIndividualReceiver
      ? (typeOfReceiverSelected) => {
          const isTypeOfReceiverChooseAgain =
            information[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME] ===
            typeOfReceiverSelected;
          if (isTypeOfReceiverChooseAgain) {
            return;
          }

          const isTypeOfBusinessReceiverSelected = typeOfReceiverSelected === 2;

          const message = t('popup_change_receiver_type_warning');
          showPortalModalName({
            name: PORTAL_NAME_MODAL_DEFINE.CREATE_RECEIVER_MODAL,
            title: '',
            content: message,
            action: () => {
              const newInformation = {
                ...initialFormField,
                [EDIT_RECEIVER_COUNTRY_NAME]:
                  information[EDIT_RECEIVER_COUNTRY_NAME] || null,
                [EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME]:
                  typeOfReceiverSelected,
                [EDIT_RECEIVER_GENDER_NAME]: isTypeOfBusinessReceiverSelected
                  ? BUSINESS_OF_RECEIVER_TYPE
                  : '',
              };
              setInformation(newInformation);
              onReset({ isRedirect: true });
            },
            actionClose: () => {
              setInformation(information);
            },
          });
        }
      : (typeOfReceiverSelected) =>
          handleTypeOfReceiverOnChange(typeOfReceiverSelected);

  const isAllowRenderSelectTypeOfReceiverFormSection =
    information[EDIT_RECEIVER_COUNTRY_NAME];
  const renderSelectTypeOfReceiverForm = () => {
    if (!isAllowRenderSelectTypeOfReceiverFormSection) return null;
    return (
      <FormInputSelectTypeOfReceiverRadio
        countryConfig={countryConfig}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        onClick={handleSendMoneyEditReceiverTypeOnClick}
      />
    );
  };

  const renderNameForm = () => {
    if (isReceiverOfIndividualType) {
      if (isVietNamCountry)
        return (
          <>
            {EDIT_RECEIVER_GENERAL_DETAILS_NAME_FIELDS.slice(0, 2).map(
              (field) => (
                <FormInputNameValidation
                  key={field.name}
                  field={field}
                  information={information}
                  setInformation={setInformation}
                  validation={validation}
                  setValidation={setValidation}
                />
              )
            )}
          </>
        );

      if (!isVietNamCountry)
        return (
          <>
            {EDIT_RECEIVER_GENERAL_DETAILS_NAME_FIELDS.slice(0, 2)
              .reverse()
              .map((field) => (
                <FormInputNameValidation
                  key={field.name}
                  field={field}
                  information={information}
                  setInformation={setInformation}
                  validation={validation}
                  setValidation={setValidation}
                />
              ))}
          </>
        );
    }

    if (isReceiverOfBusinessType) {
      return (
        <FormInputValidation
          key={EDIT_RECEIVER_BUSINESS_NAME_FIELD.name}
          field={EDIT_RECEIVER_BUSINESS_NAME_FIELD}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          styles={{
            width: '100%',
            float: 'unset',
            marginLeft: '0px',
            marginRight: '0px',
          }}
        />
      );
    }

    return null;
  };

  const renderFullNameForm = () => {
    if (isVietNamCountry)
      return (
        <FormInputNameValidation
          field={EDIT_RECEIVER_GENERAL_DETAILS_NAME_FIELDS[2]}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
        />
      );

    return null;
  };

  const renderGenderForm = () => {
    return (
      <FormInputGenderRadio
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    );
  };

  const renderMobileForm = () => {
    if (mobile?.visible)
      return (
        <FormInputMobileOrPhoneNumberValidation
          field={EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS[0]}
          config={mobile}
          numberMaskStr={mobileMaskStr}
          setNumberMaskStr={setMobileMaskStr}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          setEitherRequiredMsg={setEitherRequiredMsg}
          styles={
            !phone.visible
              ? {
                  float: 'unset',
                  width: '100%',
                }
              : {}
          }
        />
      );

    return null;
  };

  const renderPhoneForm = () => {
    if (phone?.visible && isReceiverOfIndividualType)
      return (
        <FormInputMobileOrPhoneNumberValidation
          field={EDIT_RECEIVER_GENERAL_DETAILS_PHONE_NUMBER_FIELDS[1]}
          config={phone}
          numberMaskStr={phoneMaskStr}
          setNumberMaskStr={setPhoneMaskStr}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          setEitherRequiredMsg={setEitherRequiredMsg}
          styles={
            !mobile.visible
              ? {
                  float: 'unset',
                  width: '100%',
                }
              : {}
          }
        />
      );

    if (phone?.visible && isReceiverOfBusinessType)
      return (
        <FormInputMobileOrPhoneNumberValidation
          field={EDIT_RECEIVER_BUSINESS_PHONE_FIELD}
          config={phone}
          numberMaskStr={phoneMaskStr}
          setNumberMaskStr={setPhoneMaskStr}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          setEitherRequiredMsg={setEitherRequiredMsg}
          styles={{
            float: 'unset',
            width: '100%',
            marginInline: '0px',
          }}
        />
      );

    return null;
  };

  const renderRelationshipForm = () => {
    if (relationship?.visible && isReceiverOfIndividualType)
      return (
        <CustomSearchDropdown
          field={EDIT_RECEIVER_RELATIONSHIP_FIELD}
          isConfigRequired={relationship?.required}
          data={configRelationship}
          keyFind="key"
          keyTranslate="value"
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          isClearBoth
          scrollUp={true}
        />
      );

    if (isReceiverOfBusinessType)
      return (
        <CustomSearchDropdown
          field={EDIT_RECEIVER_RELATIONSHIP_FIELD}
          isConfigRequired={true}
          data={configRelationshipOfBusiness}
          keyFind="key"
          keyTranslate="value"
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          isClearBoth
          scrollUp={true}
        />
      );

    return null;
  };

  const isAllowRenderNextSection =
    information[EDIT_RECEIVER_COUNTRY_NAME] &&
    information[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME];
  const renderNextSection = () => {
    if (!isAllowRenderNextSection) return null;
    if (isReceiverOfIndividualType)
      return (
        <>
          {renderNameForm()}
          {renderFullNameForm()}
          {renderGenderForm()}
          {renderMobileForm()}
          {renderPhoneForm()}
          {Boolean(isShowEitherRequiredMsg) && (
            <ControlFeedback>{t(eitherRequiredMsg)}</ControlFeedback>
          )}
          {renderRelationshipForm()}
        </>
      );
    if (isReceiverOfBusinessType)
      return (
        <>
          {renderNameForm()}
          {renderPhoneForm()}
          {renderRelationshipForm()}
        </>
      );
    return null;
  };

  const renderActionsButton = () => {
    if (isMobile || isTablet)
      return (
        <Fade in appear>
          <NextWrap>
            <ButtonCommon
              value={t('button_next')}
              color="var(--c-primary)"
              background="var(--bg-primary)"
              isFill={true}
              styles={{
                margin: '0px',
                width: '100%',
              }}
              onClick={handleNextStep}
              isUseKeyDown
            />
          </NextWrap>
        </Fade>
      );

    return (
      <Fade in appear>
        <NextWrap>
          <ButtonCommon
            value={t('button_cancel')}
            onClick={handleOnToggleCancelPopup}
            styles={{
              margin: '0px',
              width: '50%',
              opacity: 0,
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
            isDisabled={true}
          />
          <ButtonCommon
            value={t('button_next')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            onClick={handleNextStep}
            isUseKeyDown
          />
        </NextWrap>
      </Fade>
    );
  };

  return (
    <>
      <BodySectionHeader
        titleBodySection={(isMobile || isTablet) && t('add_receiver_title')}
        titleSection={t('label_general_details')}
        onToggleCancelPopup={handleOnToggleCancelPopup}
        isDisabled={false}
      />
      <PersonalDetailsStyled>
        <CustomSearchDropdown
          field={EDIT_RECEIVER_COUNTRY_FIELD}
          data={countries}
          keyFind="name"
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          onChange={handleSendMoneyEditReceiverCountryOnClick}
        />
        {renderSelectTypeOfReceiverForm()}
        {renderNextSection()}
        {renderActionsButton()}
      </PersonalDetailsStyled>
    </>
  );
};

const PersonalDetailsStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 240px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 437px;
    margin-top: 0px;
    padding-inline: 0px;
    // padding-bottom: unset;
  }
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77px;
  width: 100%;
  margin-inline: -16px;
  padding-inline: 16px;
  background: var(--ds-c-white);
  z-index: 1;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #eef2f5;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    width: 437px;
    height: 44px;
    border-top: unset;
  }
`;

const ControlFeedback = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: relative;
  top: -30px;
  margin: 0;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
    display: table;
  }
`;

export default PersonalDetails;
