/* eslint-disable no-unused-vars */
import ValidIcon from 'assets/icons/done-icon.svg';
import SendIcon from 'assets/icons/send-icon.svg';
import InvalidIcon from 'assets/icons/warnning-red-icon.svg';
import DiscountFooterSvg from 'assets/images/discount/discount_footer.svg';
import DiscountHeaderSvg from 'assets/images/discount/discount_header.svg';
import DiscountHeaderComingSoonSvg from 'assets/images/discount/discount_header_coming_soon.svg';
import DiscountHeaderDisabledSvg from 'assets/images/discount/discount_header_disabled.svg';
import { handleApplyDiscount } from 'components/Discount/func';
import ButtonCommon from 'components/common/ButtonCommon';
import SizeBox from 'components/common/SizeBox';
import { findCountry } from 'components/common/func';
import {
  ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_RECEIVER_GET_NAME,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  DESKTOP_MIN_WIDTH,
  DISCOUNT_ACTIVE,
  DISCOUNT_COMING_SOON,
  DISCOUNT_EXPIRED,
  DISCOUNT_FULLY_REDEEMED,
  DISCOUNT_METHOD,
  DISCOUNT_REDEEMED,
  FX_TRANSACTION_ADD_PREFIX,
  MOBILE_SMALL_MAX_WIDTH,
  MOBILE_SMALL_MIN_WIDTH,
  PAYMENT_METHOD_TEXT_REFERENCE,
  TABLET_MAX_WIDTH,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import {
  chipModalStyles,
  datePicker,
  format,
  getChipValue,
  getDiscountStatus,
  getDiscountUnit,
  getValidText,
  textModalColor,
} from 'helpers';
import useAuth from 'hooks/useAuth';
import useAutoScaleToFitContainer from 'hooks/useAutoScaleToFitContainer';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useToastify from 'hooks/useToastify';
import { useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import { numberUtils } from 'utils';

const HEADER_COL_WIDTH = 134;

export const DiscountModal = ({
  isOpen,
  discount,
  onClose,
  onClick,
  onApplyError,
  inactive,
  isBackdropOverlap,
  zIndexBackdropOverlap,
  zIndexModal = 0,
}) => {
  const { t, locate = EN_LANG } = useLang();
  const { pathname } = useLocation();
  const { token } = useAuth();
  const { state } = useStore();
  const { addTransactionInformation, currentReceiver } = state;
  const { receiverPayoutMethod, remittance } = addTransactionInformation || {};

  const isCreateTxn =
    pathname === `${TRANSACTION_ADD_PREFIX}` ||
    pathname.includes(`${FX_TRANSACTION_ADD_PREFIX}`);

  const countryCode = currentReceiver?.country?.code;
  const sendAmount = Number(remittance[ADD_TRANSACTION_YOU_SEND_NAME] || 0);
  const sendAnotherAmount = Number(
    remittance[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0
  );
  const sendTotalAmount = Number(sendAmount + sendAnotherAmount);
  const receivedAmount = Number(remittance[ADD_TRANSACTION_RECEIVER_GET_NAME]);
  const currencyCodeSelected =
    remittance?.currentCurrencySelected?.currencyCode;
  const feeAmount = numberUtils.mathRound(
    remittance?.feeAmount?.feeAmount || 0
  );
  const paymentMethodStore =
    addTransactionInformation?.paymentMethod?.[
      ADD_TRANSACTION_PAYMENT_METHOD_NAME
    ];
  const payoutMethodStore =
    receiverPayoutMethod[ADD_TRANSACTION_PAYOUT_METHOD_NAME];

  const {
    country,
    code,
    discountMethod,
    discountAmount,
    startDate = 0,
    endDate = 0,
    redeem = 0,
    limitTxnPerCustomer = 0,
    lastRedeemAt = 0,
    descriptions,
    minTxnAmount = 0,
    maxTxnAmount = 0,
    currencyCode,
    deliveryMethod,
    paymentMethod,
  } = discount || {};
  const { description } = descriptions?.[locate] || {};
  const {
    status,
    discountMethodKey,
    isActive,
    isActiveRedeemLast,
    isDiscountCodeHidden,
    isDiscountActiveUsedHidden,
  } = getDiscountStatus(discount || null) || {};

  const isActiveAndAvailable = isActive && !inactive;
  const isActiveAndUnavailable = isActive && inactive;

  const isShowFlatFeeOfDiscountCondition = Boolean(
    discountMethod === DISCOUNT_METHOD.FLAT_FEE &&
      Number(discountAmount) > 0 &&
      Boolean(Number(discountAmount) > Number(feeAmount))
  );
  /* eslint-disable no-extra-boolean-cast */
  const isFlatFeeOfDiscountInvalid =
    isShowFlatFeeOfDiscountCondition &&
    (inactive || Boolean(Number(discountAmount) > Number(feeAmount)));

  const discountAmountUnitClassName = `discount-modal-amount-unit-${code}`;
  useAutoScaleToFitContainer({
    className: discountAmountUnitClassName,
    maxWidth: HEADER_COL_WIDTH,
    alignHorizontal: 'left',
    alignVertical: 'bottom',
  });

  const discountCodeClassName = `discount-modal-code-${code}`;
  useAutoScaleToFitContainer({
    className: discountCodeClassName,
    maxWidth: HEADER_COL_WIDTH,
    alignHorizontal: 'right',
  });

  const discountHeaderSvg = {
    [DISCOUNT_ACTIVE]: DiscountHeaderSvg,
    [DISCOUNT_COMING_SOON]: DiscountHeaderComingSoonSvg,
    [DISCOUNT_REDEEMED]: DiscountHeaderDisabledSvg,
    [DISCOUNT_EXPIRED]: DiscountHeaderDisabledSvg,
    [DISCOUNT_FULLY_REDEEMED]: DiscountHeaderDisabledSvg,
  };

  const { triggerToaster } = useToastify({
    content: t('label_discount_applied'),
  });

  const footerContent = useMemo(
    () => ({
      [DISCOUNT_ACTIVE]: (
        <FooterContent $isCenter={!onClick || inactive}>
          <ButtonCommon
            value={t('button_close')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
          {onClick && !inactive && (
            <>
              {isCreateTxn ? (
                <ButtonCommon
                  value={t('button_apply')}
                  color="var(--c-primary)"
                  background="var(--bg-primary)"
                  isFill={true}
                  styles={{
                    width: '50%',
                  }}
                  onClick={() => {
                    handleApplyDiscount({
                      token,
                      item: discount,
                      onApplyError,
                      onApplySuccess: triggerToaster,
                    });
                  }}
                />
              ) : (
                <ButtonCommon
                  value={t('button_send')}
                  color="var(--c-primary)"
                  background="var(--bg-primary)"
                  isFill={true}
                  styles={{
                    paddingInline: 0,
                  }}
                  buttonIconStyles={{
                    float: 'right',
                    width: '50%',
                  }}
                  iconSrc={SendIcon}
                  onClick={onClick}
                />
              )}
            </>
          )}
        </FooterContent>
      ),
      [DISCOUNT_COMING_SOON]: (
        <FooterContent $isCenter>
          <ButtonCommon
            value={t('button_close')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
        </FooterContent>
      ),
      [DISCOUNT_REDEEMED]: (
        <FooterContent $isCenter>
          <ButtonCommon
            value={t('button_close')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
        </FooterContent>
      ),
      [DISCOUNT_EXPIRED]: (
        <FooterContent $isCenter>
          <ButtonCommon
            value={t('button_close')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
        </FooterContent>
      ),
      [DISCOUNT_FULLY_REDEEMED]: (
        <FooterContent $isCenter>
          <ButtonCommon
            value={t('button_close')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
        </FooterContent>
      ),
    }),
    [status]
  );

  const getConditionListStyle = (props) => {
    const {
      checkByCountryCode,
      checkByReceiveAmountMin,
      checkByReceiveAmountMax,
      checkByCurrencyCode,
      checkByPaymentMethod,
      checkByPayoutMethod,
      checkByFlatFeeOfDiscount,
    } = props || {};

    if (!isCreateTxn) return <Disc>●</Disc>;

    if (isActiveAndUnavailable) {
      const isCheckByCountryCodeInvalid = Boolean(
        checkByCountryCode && country && country !== countryCode
      );
      if (isCheckByCountryCodeInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }

      const isCheckByReceiveAmountMaxInvalid =
        checkByReceiveAmountMax &&
        maxTxnAmount > 0 &&
        Boolean(
          currencyCode
            ? receivedAmount > maxTxnAmount
            : sendTotalAmount > maxTxnAmount
        );
      if (isCheckByReceiveAmountMaxInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }

      const isCheckByReceiveAmountMinInvalid = Boolean(
        checkByReceiveAmountMin &&
          Boolean(
            currencyCode
              ? receivedAmount < minTxnAmount
              : sendTotalAmount < minTxnAmount
          )
      );
      if (isCheckByReceiveAmountMinInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }

      const isCheckByCurrencyCodeOfReceiveInvalid = Boolean(
        currencyCode &&
          (checkByReceiveAmountMax || checkByReceiveAmountMin) &&
          checkByCurrencyCode &&
          currencyCodeSelected !== currencyCode
      );
      if (isCheckByCurrencyCodeOfReceiveInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }

      // const isCheckByCurrencyCodeOfSendInvalid = Boolean(
      //   !currencyCode &&
      //     (checkByReceiveAmountMax || checkByReceiveAmountMin) &&
      //     checkByCurrencyCode &&
      //     isCheckByReceiveAmountMaxInvalid &&
      //     isCheckByReceiveAmountMinInvalid
      // );
      // if (isCheckByCurrencyCodeOfSendInvalid) {
      //   return <img src={InvalidIcon} width={18} alt="" />;
      // }

      const isCheckByPayoutMethodInvalid = Boolean(
        checkByPayoutMethod && deliveryMethod !== payoutMethodStore
      );
      if (isCheckByPayoutMethodInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }

      const isCheckByPaymentMethodInvalid = Boolean(
        checkByPaymentMethod && paymentMethod !== paymentMethodStore
      );
      if (isCheckByPaymentMethodInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }

      const isCheckByFlatFeeOfDiscountInvalid = Boolean(
        checkByFlatFeeOfDiscount && isFlatFeeOfDiscountInvalid
      );
      if (isCheckByFlatFeeOfDiscountInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }
    }
    return <img src={ValidIcon} width={18} alt="" />;
  };

  const condition1 = (props = {}) => {
    return {
      text: t('label_offers_conditions_txt_1', {
        countryName: findCountry(country)?.label,
      }),
      icon: getConditionListStyle(props),
      isHide: !country,
    };
  };
  const condition2 = (props = {}) => {
    return {
      text: t('label_offers_conditions_txt_2', {
        minTxnAmount: format.toAmountStr(minTxnAmount),
        currencyCode: currencyCode || 'AUD',
      }),
      icon: getConditionListStyle(props),
      isHide: Number(minTxnAmount) === 0,
    };
  };
  const condition3 = (props = {}) => {
    return {
      text: t('label_offers_conditions_txt_3', {
        maxTxnAmount: format.toAmountStr(maxTxnAmount),
        currencyCode: currencyCode || 'AUD',
      }),
      icon: getConditionListStyle(props),
      isHide: Number(maxTxnAmount) === 0,
    };
  };
  const condition4 = (props = {}) => {
    return {
      text: t('label_offers_conditions_txt_4', {
        limitTxnPerCustomer,
      }),
      icon: getConditionListStyle(props),
      isHide:
        Number(limitTxnPerCustomer) === 0 || Number(limitTxnPerCustomer) === 1,
    };
  };
  const condition5 = (props = {}) => {
    return {
      text: t('label_offers_conditions_txt_5', {
        paymentMethod: t(
          PAYMENT_METHOD_TEXT_REFERENCE[paymentMethod]
        )?.toUpperCase(),
      }),
      icon: getConditionListStyle(props),
      isHide: !paymentMethod,
    };
  };
  const condition6 = (props = {}) => {
    return {
      text: t(`label_offers_conditions_txt_6_${deliveryMethod?.toLowerCase()}`),
      icon: getConditionListStyle(props),
      isHide: !deliveryMethod,
    };
  };
  const condition7 = (props = {}) => {
    return {
      text: t(`label_offers_conditions_txt_7`),
      icon: getConditionListStyle(props),
      isHide: discountMethod !== DISCOUNT_METHOD.FLAT_FEE,
    };
  };
  const conditions = [
    condition1({ checkByCountryCode: true }),
    condition2({
      checkByReceiveAmountMin: true,
      checkByCurrencyCode: true,
    }),
    condition3({
      checkByReceiveAmountMax: true,
      checkByCurrencyCode: true,
    }),
    condition6({
      checkByPayoutMethod: true,
    }),
    condition4(),
    condition5({ checkByPaymentMethod: true }),
    condition7({ checkByFlatFeeOfDiscount: true }),
  ];

  return (
    <>
      <style>
        {zIndexModal &&
          `
          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal {
              z-index: ${zIndexModal || '1101'};
            }
          }
        `}
        {isBackdropOverlap
          ? `
          .discount-modal-backdrop {
            z-index: ${zIndexBackdropOverlap || '1100'};
            background: #212121;
            opacity: 0.25!important;
            display: initial!important;
          }
        `
          : `
        .discount-modal-backdrop {
          background: #212121;
          opacity: 0.25!important;
          display: initial!important;
        }
      `}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={onClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="discount-modal-backdrop"
        centered
        $isOpen={isOpen}
      >
        <ModalHeader>
          <DiscountHeader
            $inactive={inactive}
            src={discountHeaderSvg[status]}
            width={300}
            height={status === DISCOUNT_COMING_SOON ? 123 : 127}
          />
          <HeaderContent>
            <HeaderColLeft>
              <ScaleAmountUnitContainer>
                <DiscountAmountUnitText
                  className={discountAmountUnitClassName}
                  style={{
                    color: textModalColor()[status],
                  }}
                >
                  {format.toAmountStr(Number(discountAmount))}
                  <span>{getDiscountUnit(discountMethod)}</span>
                </DiscountAmountUnitText>
              </ScaleAmountUnitContainer>
              <SizeBox height={8} />
              <DiscountMethodText
                style={{
                  color: textModalColor()[status],
                }}
              >
                {t(discountMethodKey)}
              </DiscountMethodText>
            </HeaderColLeft>
            <HeaderColRight>
              {!isDiscountCodeHidden ? (
                <ScaleCodeContainer>
                  <CodeText
                    className={discountCodeClassName}
                    style={{
                      color: textModalColor()[status],
                    }}
                  >
                    {code}
                  </CodeText>
                </ScaleCodeContainer>
              ) : (
                <SizeBox height={21} />
              )}
              {isDiscountActiveUsedHidden ? (
                <SizeBox height={26} />
              ) : (
                <>
                  <SizeBox height={4} />
                  <ChipWrap style={chipModalStyles()[status]}>
                    <ChipText>
                      {
                        getChipValue({
                          t,
                          redeem,
                          limitTxnPerCustomer,
                        })[status]
                      }
                    </ChipText>
                  </ChipWrap>
                </>
              )}
            </HeaderColRight>
          </HeaderContent>
          <div
            style={{
              width: '100%',
              height: '92px',
            }}
          />
        </ModalHeader>
        <ModalBody>
          <DescText>{description}</DescText>
          <SizeBox height={4} />
          {Boolean(conditions?.length) && (
            <>
              <ConditionsText>{t('label_offers_conditions')}:</ConditionsText>
              <ConditionList>
                {conditions?.map((item, key) => (
                  <li key={key} style={{ display: item?.isHide && 'none' }}>
                    {item?.icon}
                    <Paragraph
                      style={{
                        color:
                          item?.icon?.props?.src?.includes(
                            'warnning-red-icon'
                          ) && 'red',
                      }}
                      dangerouslySetInnerHTML={{ __html: item?.text }}
                    />
                  </li>
                ))}
              </ConditionList>
            </>
          )}
          {isActiveRedeemLast && (
            <LastUseText>
              {`${t(
                'label_offers_last_use'
              )} ${datePicker.convertToLocalDateTimeWithTimeZone(
                new Date(lastRedeemAt)
              )}`}
            </LastUseText>
          )}
          {endDate && (
            <ExpiredText
              style={{ marginBottom: 0 }}
              dangerouslySetInnerHTML={{
                __html: getValidText({
                  t,
                  startDate,
                  endDate,
                  lastRedeemAt,
                  isAustraliaTime: true,
                })[status],
              }}
            />
          )}
        </ModalBody>
        <ModalFooter
          style={{
            justifyContent: !isActiveAndAvailable && 'center',
          }}
        >
          <DiscountFooter src={DiscountFooterSvg} width={300} height={125} />
          {footerContent[status]}
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  padding-left: 0px !important;
  padding-right: 0px !important;
  transform: ${(props) => (props.$isOpen ? 'opacity: 1' : 'opacity: 0')};
  transition: 1s ease;
  & .modal-dialog {
    transform: none;
    & .modal-content {
      padding: 0px;
      border-radius: 24px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 300px;
      height: fit-content;
      margin-inline: auto;
      border: none;
    }
  }

  @media screen and (min-width: ${MOBILE_SMALL_MIN_WIDTH}px) and (max-width: ${MOBILE_SMALL_MAX_WIDTH}px) {
    z-index: 1100;
  }
`;
const ModalHeader = styled(Modal.Header)`
  position: relative;
  display: flex;
  padding-inline: 16px;
  padding-block: 0px;
  border: none;
`;
const ModalBody = styled(Modal.Body)`
  width: 100%;
  padding: 16px;
`;
const ModalFooter = styled(Modal.Footer)`
  position: relative;
  height: 70px;
  padding: 0;
  margin: 0;
  top: -3px;
  background: #fff;
  border: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    top: -2px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 0px;
  padding-inline: 16px;
  height: 92px;
`;
const HeaderColLeft = styled.div`
  flex: 1;
  display: grid;
  align-content: end;
  margin-bottom: 12px;
`;
const HeaderColRight = styled.div`
  flex: 1;
  display: grid;
  align-content: end;
  justify-content: right;
  justify-items: right;
  margin-bottom: 12px;
`;

const FooterContent = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: ${(props) => (props.$isCenter ? 'center' : 'space-between')};
  margin: 0px;
  padding-inline: 16px;
  height: 90px;
  padding-top: 16px;
  border-top: 1px dashed var(--ds-c-grey-hover);
`;

const DiscountFooter = styled.img`
  position: absolute;
  bottom: -40px;
  left: 0px;
  margin: 0px;
`;

const DiscountHeader = styled.img`
  position: absolute;
  top: -28px;
  left: 0;
  tint-color: gray;
  filter: ${(props) => props.$inactive && 'var(--ds-f-discount-inactive)'};
`;

const ChipWrap = styled.div``;
const ChipText = styled.p`
  ${paragraphMixin};
  text-align: center;
  font-size: 12px;
  line-height: normal;
  color: #fff;
  font-weight: 600;
`;

const LastUseText = styled.p`
  ${paragraphMixin};
  text-align: left;
  margin-block: 8px;
  color: var(--ds-c-dark-gray);

  & p {
    font-weight: 500;
    font-size: 12px;
    color: var(--ds-c-grey-dark);
    margin: 0;
  }

  & span {
    color: red;
  }
`;

const ExpiredText = styled.p`
  ${paragraphMixin};
  text-align: left;
  margin-block: 8px;
  color: var(--ds-c-blue);

  & p {
    font-weight: 500;
    font-size: 12px;
    color: var(--ds-c-grey-dark);
    margin: 0;
  }

  & span {
    color: red;
  }
`;

const DescText = styled.p`
  ${paragraphMixin};
  text-align: left;
`;

const ScaleCodeContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  width: ${HEADER_COL_WIDTH}px;
  height: 20px;
`;
const CodeText = styled.p`
  ${paragraphMixin};
  font-family: var(--ff-secondary);
  font-size: 20px;
  font-weight: 600;
  text-align: right;
  white-space: nowrap;
`;

const DiscountMethodText = styled.p`
  ${paragraphMixin};
  text-align: left;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0px;
`;

const ScaleAmountUnitContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: ${HEADER_COL_WIDTH}px;
  height: 65px;
`;
const DiscountAmountUnitText = styled.p`
  ${paragraphMixin};
  text-align: left;
  font-size: 65px;
  line-height: 1;

  & span {
    font-size: 30px;
  }
`;

const ConditionsText = styled.p`
  ${paragraphMixin};
  text-align: left;
  color: var(--ds-c-blue);
  margin-bottom: 8px;
`;
const ConditionList = styled.ul`
  margin: 0;
  margin-bottom: 0px;
  padding-left: 0px;

  & li {
    display: flex;
    align-items: flex-start;

    & img {
      margin-right: 4px;
    }

    & p {
      margin-bottom: 0px;
    }
  }
`;

const Paragraph = styled.p`
  ${paragraphMixin};
  font-weight: 500;
  margin-bottom: 8px;
`;

const Disc = styled.span`
  font-size: 6px;
  line-height: 3.5;
  margin-right: 8px;
`;
