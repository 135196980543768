import EditIcon from 'assets/ver2/icons/edit-blue-icon.svg';
import { fetchReceiverInformation } from 'components/Receivers/func';
import {
  ADD_TRANSACTION_METHOD_HOME_NAME,
  ADD_TRANSACTION_RECEIVER_NAME,
  DESKTOP_MIN_WIDTH,
  METHOD_HOME,
} from 'constants';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import useCheckServiceAlerts from 'hooks/service-alerts/useCheckServiceAlerts';
import useServiceAlertsConfig from 'hooks/service-alerts/useServiceAlertsConfig';
import useServiceAlertsModal from 'hooks/service-alerts/useServiceAlertsModal';
import useServiceAlertsStore from 'hooks/service-alerts/useServiceAlertsStore';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useStore from 'hooks/useStore';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { SET_EDIT_RECEIVER_INFORMATION } from 'store/action';
import styled from 'styled-components';
import FormEditAddress from '../../FormEditAddress';
import FormEditAddressMobile from '../../FormEditAddress/mobile/ver2';

const HomePayoutInformation = ({
  information,
  setInformation,
  validation,
  setValidation,
  isDisabled,
}) => {
  const { state, dispatch } = useStore();
  const { currentReceiver, editReceiverInformation } = state;
  const { token } = useAuth();
  const { isMobile, isTable, isDesktop } = useDetectDevice();

  const [isShowFormEditAddressPopup, setShowFormEditAddressPopup] =
    useState(false);

  const { fullAddress } = information?.[ADD_TRANSACTION_RECEIVER_NAME] || {};

  const { handleShowServiceAlertsModal } = useServiceAlertsModal();
  const { setPayoutMethodItemByHOME, resetRemittances } =
    useFormAddTransactionStore();
  const { cityId, suburbId, payoutMethod } = useServiceAlertsConfig();
  const {
    serviceAlertsByCountryCode: serviceAlertsStore,
    resetServiceAlertsStoreByPayoutMethodItem,
  } = useServiceAlertsStore();
  const { serviceAlertsByPayoutMethod } = serviceAlertsStore;
  const { checkByPayoutMethod, checkByPayoutMethodItemChild } =
    useCheckServiceAlerts();
  const { checkServiceAlertsOfPayoutMethodItemDisable } = checkByPayoutMethod;
  const { checkServiceAlertsOfPayoutMethodItemItemChildDisable } =
    checkByPayoutMethodItemChild;
  const isServiceAlertsOfPayoutMethodItemDisableGlobal =
    checkServiceAlertsOfPayoutMethodItemDisable({
      serviceAlertsStore,
      payoutMethod: payoutMethod || '',
    });
  const isServiceAlertsOfPayoutMethodItemItemChildDisable =
    checkServiceAlertsOfPayoutMethodItemItemChildDisable({
      serviceAlertsStore,
      payoutItemId: cityId,
      payoutItemChildId: suburbId,
    });

  const handleOnOpenFormEditAddressPopup = async () => {
    if (!currentReceiver) {
      fetchReceiverInformation(
        token,
        information[ADD_TRANSACTION_RECEIVER_NAME]?.receiverId
      );
    }
    setShowFormEditAddressPopup(true);
  };

  const handleOnCloseFormEditAddressPopup = (data) => {
    setShowFormEditAddressPopup(false);

    if (!currentReceiver) {
      dispatch({
        type: SET_EDIT_RECEIVER_INFORMATION,
        payload: {
          ...editReceiverInformation,
          residentialAddress: {
            ...editReceiverInformation.residentialAddress,
            isEditReceiverEnterAddressManuallySelected: false,
          },
        },
      });
      setChecked(false);
    }

    if (!isEmpty(data)) {
      const serviceAlerts =
        checkByPayoutMethodItemChild.getServiceAlertsByPayoutMethodItemChildWhenEditAddress(
          {
            payoutMethodItemId: data?.cityId || '',
            payoutMethodItemChildId: data?.suburbId || '',
          }
        );
      if (serviceAlerts?.type) {
        const { message, type } = serviceAlerts || {};
        handleShowServiceAlertsModal({
          message,
          type,
          onClick: resetRemittances,
        });
      }
    }
  };

  const handlePayoutMethodItemSelected = () => {
    const newInformation = {
      ...information,
      [ADD_TRANSACTION_METHOD_HOME_NAME]: {
        dmCode: METHOD_HOME,
        bank: null,
        pickupOffice: null,
        ewallet: null,
      },
      currentPayoutItemSelectedIndex: 0,
    };
    setInformation(newInformation);

    const newValidation = {
      ...validation,
      [ADD_TRANSACTION_METHOD_HOME_NAME]: '',
    };
    setValidation(newValidation);

    setPayoutMethodItemByHOME(0);

    if (isServiceAlertsOfPayoutMethodItemItemChildDisable) {
      resetServiceAlertsStoreByPayoutMethodItem();
    }
  };

  useEffect(() => {
    if (isServiceAlertsOfPayoutMethodItemDisableGlobal) return;

    if (isServiceAlertsOfPayoutMethodItemItemChildDisable) return;

    if (
      isEmpty(serviceAlertsByPayoutMethod) ||
      !isServiceAlertsOfPayoutMethodItemItemChildDisable
    ) {
      handlePayoutMethodItemSelected();
    }
  }, [
    serviceAlertsByPayoutMethod,
    isServiceAlertsOfPayoutMethodItemDisableGlobal,
    isServiceAlertsOfPayoutMethodItemItemChildDisable,
    isShowFormEditAddressPopup,
  ]);
  useEffect(() => {
    if (isServiceAlertsOfPayoutMethodItemDisableGlobal) return;
    handlePayoutMethodItemSelected();
  }, [isServiceAlertsOfPayoutMethodItemDisableGlobal]);

  return (
    <>
      {(isMobile || isTable) && isShowFormEditAddressPopup && (
        <FormEditAddressMobile
          isOpen={isShowFormEditAddressPopup}
          information={information}
          onClose={handleOnCloseFormEditAddressPopup}
        />
      )}

      {isDesktop && isShowFormEditAddressPopup && (
        <FormEditAddress
          isOpen={isShowFormEditAddressPopup}
          information={information}
          onClose={handleOnCloseFormEditAddressPopup}
        />
      )}

      <HomePayoutInformationStyled
        $isActive={
          isServiceAlertsOfPayoutMethodItemItemChildDisable ? false : true
        }
        $isDisabled={false}
      >
        <GridItem $isDisabled={false}>
          <AddressText onClick={handlePayoutMethodItemSelected}>
            {fullAddress}
          </AddressText>
          {!isDisabled && (
            <EditText onClick={handleOnOpenFormEditAddressPopup}>
              <EditIconStyled src={EditIcon} width={24} height={24} alt="" />
            </EditText>
          )}
        </GridItem>
      </HomePayoutInformationStyled>
    </>
  );
};

const HomePayoutInformationStyled = styled.div`
  width: 100%;
  height: fit-content;
  padding: 8px;
  margin-bottom: 12px;
  border: ${(props) =>
    props.$isActive && '1px solid var(--ds-c-green-default)'};
  background: var(--ds-bg-1);
  border-radius: 12px;
  cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};

  ${(props) =>
    props.$isDisabled &&
    `
    background-color: #eeeeee;
    color: #7a8189;
  `};
`;
const GridItem = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;

  &:nth-child(1) {
    justify-content: start;
    align-items: start;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      align-items: center;
    }
  }

  & div {
    & div,
    input {
      cursor: ${(props) => props.$isDisabled && 'not-allowed'};
    }
  }

  & p {
    cursor: ${(props) => props.$isDisabled && 'not-allowed'};
  }
`;
const AddressText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;
  text-align: left;

  margin: 0;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const EditText = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
`;
const EditIconStyled = styled.img``;

export default HomePayoutInformation;
