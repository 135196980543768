import { api } from 'api';
import { LogUtil } from 'components/common/LogUtil';
import { DISCOUNT_METHOD } from 'constants';
import { getDiscountStatus } from 'helpers';
import useCommonStore from 'hooks/useCommonStore';
import useLang from 'hooks/useLang';
import useToastify from 'hooks/useToastify';
import { arrayUtils } from 'utils';
import useFxAddTransaction from './useFxAddTransaction';
import useFxFormAddTransactionStore from './useFxFormAddTransactionStore';

const useFxDiscount = () => {
  const { t } = useLang();

  const {
    getCurrenciesPayload,
    getSubTotalAmount,
    getServiceFeeAmount,
    paymentMethodName,
    setDiscountsAvailable,
    setDiscountApplied,
    getCurrentUserPayloadTransform,
  } = useFxFormAddTransactionStore();
  const { isFxAsGuest } = useFxAddTransaction();

  const { setLoading, currentUser } = useCommonStore();

  const { triggerToaster } = useToastify({
    content: t('label_discount_applied'),
  });

  const filterFxDiscountsAvailable = ({
    discounts,
    countryCode,
    deliveryMethod,
    currencyCode,
    isOnlyCurrency,
    sendAmount,
    receiverAmount,
    feeAmount,
    paymentMethod = '',
  }) => {
    const discountsValid = discounts.filter((d) => {
      const { isActive, isComingSoon } = getDiscountStatus(d);

      if (
        (isActive || isComingSoon) &&
        (!d.country || d.country === countryCode) &&
        (!d.deliveryMethod || d.deliveryMethod === deliveryMethod) &&
        (!d.currencyCode ? true : isOnlyCurrency) &&
        (!d.currencyCode ? true : d.currencyCode === currencyCode) &&
        (Number(d.maxTxnAmount) > 0
          ? d.currencyCode
            ? receiverAmount >= (Number(d.minTxnAmount) || 0) &&
              receiverAmount <= Number(d.maxTxnAmount)
            : sendAmount >= (Number(d.minTxnAmount) || 0) &&
              sendAmount <= Number(d.maxTxnAmount)
          : true) &&
        (!d.paymentMethod || d.paymentMethod === paymentMethod) &&
        (d.discountMethod === DISCOUNT_METHOD.FLAT_FEE &&
        Number(d?.discountAmount) > 0
          ? Number(d?.discountAmount) <= Number(feeAmount)
          : true)
      ) {
        return d;
      }
    });

    return discountsValid;
  };

  const fetchFxDiscountsAvailable = async ({ token }) => {
    setLoading(true);
    try {
      const sendAmount = getCurrenciesPayload()?.[0]?.audAmount;
      const receivingAmount = getCurrenciesPayload()?.[0]?.amount;
      const currencyCode = getCurrenciesPayload()?.[0]?.code;
      const txnAmount = getSubTotalAmount();
      const txnFee = getServiceFeeAmount();

      const fetchFxDiscountsActive = async () => {
        if (!isFxAsGuest)
          return api.getFxDiscountsActive({
            token,
            receivingAmount,
            txnAmount,
            txnFee,
          });
        return api.getFxDiscountsActiveAsGuest({
          receivingAmount,
          txnAmount,
          txnFee,
        });
      };

      const { data } = await fetchFxDiscountsActive();

      const discountsAvailable = filterFxDiscountsAvailable({
        discounts: data,
        countryCode: '',
        deliveryMethod: '',
        currencyCode,
        isOnlyCurrency: true,
        sendAmount,
        receiverAmount: receivingAmount,
        feeAmount: txnFee,
        paymentMethod: paymentMethodName,
      });

      const discountsInactive = arrayUtils.filterFromAnotherArrayByProp(
        data,
        discountsAvailable,
        'code'
      );

      if (data && !arrayUtils.isArrayEmpty(data)) {
        setDiscountsAvailable({
          discountsAvailable,
          discountsInactive,
        });
      }

      setLoading(false);
    } catch (error) {
      LogUtil.error('fetchFxDiscountsAvailable', 'error', error?.message);
      setLoading(false);
    }
  };

  const checkFxDiscountAppliedValid = async (token, discount, onApplyError) => {
    setLoading(true);
    try {
      const receivingAmount = getCurrenciesPayload()?.[0]?.amount;
      const txnAmount = getSubTotalAmount();
      const txnFee = getServiceFeeAmount();

      const { data } = await api.applyFxDiscount({
        token,
        discount,
        currentUser: !isFxAsGuest
          ? currentUser
          : getCurrentUserPayloadTransform(),
        receivingAmount,
        txnAmount,
        txnFee,
      });

      if (data) {
        setDiscountApplied(data);
        triggerToaster();
      }

      setLoading(false);
    } catch (error) {
      const { message, response } = error || {};
      const { data } = response || {};
      const { errorCode } = data || {};

      LogUtil.error('checkFxDiscountAppliedValid', 'error', message);

      setLoading(false);

      onApplyError(errorCode || '');
    }
  };

  const handleApplyFxDiscount = ({ token, item, onApplyError }) => {
    checkFxDiscountAppliedValid(token, item, onApplyError);
  };

  const handleRemoveFxDiscountApplied = (callback = () => {}) => {
    setDiscountApplied();
    callback && callback();
  };

  return {
    fetchFxDiscountsAvailable,
    handleApplyFxDiscount,
    handleRemoveFxDiscountApplied,
  };
};

export default useFxDiscount;
