import CloseIcon from 'assets/ver2/icons/close-black-icon.svg';
import DoneIcon from 'assets/ver2/icons/done-small-icon.svg';
import {
  DESKTOP_MIN_WIDTH,
  MOBILE_MIN_WIDTH,
  TABLET_MAX_WIDTH,
} from 'constants';
import { isEmpty } from 'lodash';
import React from 'react';
import { Flip, ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import { domUtils } from 'utils';

export const TOASTIFY_POSITION = {
  TC: 'top-center',
  TR: 'top-right',
};

const TOAST_CLASSNAME = 'root-toast';

const useToastify = ({ content = '' }) => {
  const Toaster = ({ closeToast }) => (
    <ToasterStyled>
      <img src={DoneIcon} />
      <p>{content}</p>
      <img
        src={CloseIcon}
        onClick={closeToast}
        style={{
          cursor: 'pointer',
        }}
      />
    </ToasterStyled>
  );
  const triggerToaster = () => toast(<Toaster />);

  const renderToastContainer = (
    position = TOASTIFY_POSITION.TC,
    autoCloseSec = 3
  ) => (
    <ToastContainerStyled
      position={position}
      autoClose={autoCloseSec * 1000}
      limit={1}
      hideProgressBar={true}
      transition={Flip}
      draggable={false}
      pauseOnHover={false}
      toastClassName={TOAST_CLASSNAME}
    />
  );

  const isToastShowing = domUtils.isReady
    ? !isEmpty(document?.getElementsByClassName(TOAST_CLASSNAME)[0])
    : false;

  return {
    triggerToaster,
    renderToastContainer,
    isToastShowing,
  };
};

const ToastContainerStyled = styled(ToastContainer)`
  padding: 0;
  margin: 0;
  min-width: fit-content !important;
  width: fit-content !important;
  min-height: fit-content !important;
  height: fit-content !important;
  box-shadow: 0px 4px 15px 0px #0000001F;
  border-left: 6px solid var(--ds-c-green-default);
  border-radius: 4px;

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${
  TABLET_MAX_WIDTH - 1
}px) {
      margin-top: 86px;
      margin-inline: auto;
  }
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    top: 86px;
  }

  & .Toastify__toast--default {
    padding: 0;
    margin: 0;
    min-width: fit-content !important;
    width: fit-content !important;
    min-height: fit-content !important;
    height: fit-content !important;
    border-radius: 0px 4px 4px 0px;

    & .Toastify__toast-body {
      padding: 0;
      margin: 0;
      min-width: fit-content !important;
      width: fit-content !important;
      min-height: fit-content !important;
      height: fit-content !important;
    }

    & .Toastify__close-button {
      display: none;
    }

    & .Toastify__progress-bar--wrp
  }
`;
const ToasterStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding: 10px;
  border-radius: 8px;
  margin: 0;

  & p {
    ${paragraphMixin};
    font-weight: 500;
  }
`;

export default useToastify;
