import { fetchCountries } from 'components/common/func';
import { COUNTRY_FLAG_REFERENCES } from 'constants';
import { format } from 'helpers';
import useLang from 'hooks/useLang';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const ActiveItem = ({ country, rates }) => {
  const { t } = useLang();

  const [countries, setCountries] = useState(null);

  useEffect(() => {
    fetchCountries((data) => {
      setCountries(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCountryNameByCode = (countryCode) => {
    if (!countries) {
      return null;
    }
    const country = countries?.find((country) => country.code === countryCode);
    return country ? country.name : '';
  };

  const receivedCurrencies = (num) => {
    if (num === 1) {
      return t('label_received_currency', {
        number: num,
      });
    } else if (num > 1) {
      return t('label_received_currencies', {
        number: num,
      });
    } else {
      return '';
    }
  };

  if (!country) return null;

  return (
    <ItemWrap>
      <CountryName>
        <FlagIcon src={COUNTRY_FLAG_REFERENCES[country]} />
        {getCountryNameByCode(country)}
      </CountryName>
      {receivedCurrencies(Object.keys(rates).length)}
      <Currencies>
        {Object.keys(rates).length === 0 ? (
          <div>No rates available</div>
        ) : (
          Object.keys(rates).map((currencyCode) => {
            const rateInfo = rates[currencyCode];
            const rate = typeof rateInfo.rate === 'number' && !isNaN(rateInfo.rate) ? format.toAmountStr2(rateInfo.rate): '';

            if (rateInfo.rate && typeof rateInfo.rate === 'number' && !isNaN(rateInfo.rate)) {
              const formattedRate = format.toAmountStr2(rateInfo.rate);
              return (
                <div key={currencyCode}>
                  <span>{rateInfo.currencyCode}</span>{' '}
                  {formattedRate}
                </div>
              );
            } else {
              return null;
            }
          })
        )}
      </Currencies>
    </ItemWrap>
  );
};

const ItemWrap = styled.div`
  padding: 12px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 167px;

  background: var(--ds-c-black);
  color: var(--ds-c-white);
  padding: 12px 10px 20px 10px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  cursor: pointer;

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
`;

const CountryName = styled.h4`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-family: var(--ff-primary);
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: var(--ds-c-yellow);
  margin: 0 0 8px 0;
`;
const FlagIcon = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
`;

const Currencies = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  margin: 4px 0 0 0;
  font-weight: 700;
  font-size: 16px;

  span {
    border-radius: 8px;
    line-height: 20px;
    border: 1px solid var(--ds-c-white);
    background: var(--ds-c-white);
    color: var(--ds-c-black);
    height: 22px;
    font-size: 12px;
    padding: 2px 5px;
  }
`;

export default ActiveItem;
