import useServiceAlertsByCountryCodeObserver from './observers/useServiceAlertsByCountryCodeObserver';
import useServiceAlertsByPaymentObserver from './observers/useServiceAlertsByPaymentObserver';
import useServiceAlertsConfig from './useServiceAlertsConfig';

const ServiceAlerts = () => {
  const { countryCode } = useServiceAlertsConfig();

  useServiceAlertsByCountryCodeObserver({
    dbPath: `/${countryCode}`,
    variant: 'countryCode',
  });
  useServiceAlertsByPaymentObserver({
    dbPath: `/PAYMENTS`,
    variant: 'payments',
  });

  return null;
};

export default ServiceAlerts;
