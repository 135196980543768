import { HEADER_AGENT_CODE_REFERENCE } from 'constants';
import { CURRENCY_NAME_REFERENCE } from 'constants/currency';
import { useEffect, useState } from 'react';
import { SET_EXCHANGE_RATES, SET_REMITTANCE_RATES } from 'store/action';
import { arrayUtils } from 'utils';
import { getPathExchangRate } from './useFirebase';
import useRetriveDatabaseInstance from './useRetriveDatabaseInstance';
import useStore from './useStore';
import useTheme from './useTheme';

const useExchangeRates = () => {
  const { theme } = useTheme();
  const { state, dispatch } = useStore();
  const { currentUser } = state || {};
  const { liveRateCurrencies, remittanceCountriesPrefer, fxCurrenciesPrefer } =
    currentUser || {};

  const agentCode = HEADER_AGENT_CODE_REFERENCE[theme];

  const [exchangeRate1, setExchangeRate1] = useState(null);
  const [exchangeRate2, setExchangeRate2] = useState(null);
  const [exchangeRate3, setExchangeRate3] = useState(null);
  const [exchangeRate4, setExchangeRate4] = useState(null);
  const [exchangeRate5, setExchangeRate5] = useState(null);
  const [exchangeRatesData, setExchangeRatesData] = useState(null);

  const [remittanceRatesData, setRemittanceRatesData] = useState(null);

  const { retriveDataFromRealtimeDatabase } = useRetriveDatabaseInstance({
    databaseURL: process.env.REACT_APP_FIREBASE_EXCHANGE_RATE_DATABASE_URL,
    actionName: SET_REMITTANCE_RATES,
    isDisable: true,
    storeOptions: {
      isAppendToObject: true,
    },
    queryOptions: {
      isQuery: true,
      limitToLast: 1,
    },
  });

  const exchangeRateReference = {
    0: exchangeRate1,
    1: exchangeRate2,
    2: exchangeRate3,
    3: exchangeRate4,
    4: exchangeRate5,
  };
  const setExchangeRateReference = {
    0: setExchangeRate1,
    1: setExchangeRate2,
    2: setExchangeRate3,
    3: setExchangeRate4,
    4: setExchangeRate5,
  };
  useEffect(() => {
    if (liveRateCurrencies) {
      for (let i = 0; i < liveRateCurrencies.length; i++) {
        const currencyCode = liveRateCurrencies[i];

        retriveDataFromRealtimeDatabase({
          path: getPathExchangRate(currencyCode, agentCode),
          prop: currencyCode,
          callback: (exchangeRate) => {
            const createdAt = exchangeRate ? Object.keys(exchangeRate)?.[0] : 0;
            const currencyWithAgentCode =
              exchangeRate?.[createdAt]?.[agentCode];
            const currencyWithGlobal = exchangeRate?.[createdAt]?.['GLOBAL'];

            const exchangeRateData = {
              currency: {
                name: CURRENCY_NAME_REFERENCE?.[currencyCode],
                code: currencyCode,
              },
              currencyCode,
              rate: currencyWithAgentCode || currencyWithGlobal || 0,
              createdAt,
              index: i,
            };
            setExchangeRateReference[i](exchangeRateData);
          },
        });
      }
    }

    if (remittanceCountriesPrefer) {
      const result = {};

      for (const countryCode in remittanceCountriesPrefer) {
        const currencies = remittanceCountriesPrefer[countryCode];
        result[countryCode] = {};

        currencies.forEach((currencyCode) => {
          result[countryCode][currencyCode] = {}; // Initialize as an empty object
        });

        currencies.forEach((currencyCode) => {
          retriveDataFromRealtimeDatabase({
            path: getPathExchangRate(currencyCode, agentCode),
            prop: currencyCode,
            callback: (exchangeRate) => {
              const createdAt = exchangeRate
                ? Object.keys(exchangeRate)?.[0]
                : 0;
              const currencyWithAgentCode =
                exchangeRate?.[createdAt]?.[agentCode];
              const currencyWithGlobal = exchangeRate?.[createdAt]?.['GLOBAL'];

              const exchangeRateData = {
                currency: {
                  name: CURRENCY_NAME_REFERENCE?.[currencyCode],
                  code: currencyCode,
                },
                currencyCode,
                rate: currencyWithAgentCode || currencyWithGlobal || 0,
                createdAt,
              };
              result[countryCode][currencyCode] = exchangeRateData;
            },
          });
        });
      }

      setRemittanceRatesData(result);
    }
  }, [liveRateCurrencies, remittanceCountriesPrefer]);

  useEffect(() => {
    let newExchangeRatesData = arrayUtils.sortArrayObject(
      arrayUtils.removeDuplicateInArrayObject(
        [
          ...(exchangeRate1 ? [exchangeRate1] : []),
          ...(exchangeRate2 ? [exchangeRate2] : []),
          ...(exchangeRate3 ? [exchangeRate3] : []),
          ...(exchangeRate4 ? [exchangeRate4] : []),
          ...(exchangeRate5 ? [exchangeRate5] : []),
          ...(exchangeRatesData || []),
        ],
        'currencyCode'
      ),
      'index'
    );

    for (let i = 0; i < 5; i++) {
      if (exchangeRateReference[i]) {
        setExchangeRateReference[i](null);
      }
    }

    setExchangeRatesData(newExchangeRatesData);
  }, [
    exchangeRate1,
    exchangeRate2,
    exchangeRate3,
    exchangeRate4,
    exchangeRate5,
  ]);

  useEffect(() => {
    if (exchangeRatesData) {
      dispatch({
        type: SET_EXCHANGE_RATES,
        payload: exchangeRatesData,
      });
    }
    if (remittanceRatesData) {
      dispatch({
        type: SET_REMITTANCE_RATES,
        payload: remittanceRatesData,
      });
    }
  }, [exchangeRatesData, remittanceRatesData]);

  return { exchangeRatesData, remittanceRatesData, fxCurrenciesPrefer };
};

export default useExchangeRates;
