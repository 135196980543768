import ArrowLeft from 'assets/ver2/icons/arrow-left-blue.svg';
import ArrowRight from 'assets/ver2/icons/arrow-right-blue.svg';
import { TABLET_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import { FreeMode, Mousewheel, Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import FXTopRateItem from '../../FXTopRateItem';

const FXRatesSection = ({ FXRatesData }) => {
  const { t } = useLang();
  const { state } = useStore();

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    const handleSwiper = (swiper) => {
      if (swiper.params && swiper.navigation) {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.init();
        swiper.navigation.update();
      }
    };
    const swiper = document.querySelector('.swiper').swiper;
    if (swiper) {
      handleSwiper(swiper);
    }
  }, []);

  if (!FXRatesData) {
    return null;
  }
  return (
    <Wrapper>
      <div ref={prevRef} className="custom-prev">
        <img src={ArrowLeft} alt="Prev" />
      </div>
      <div ref={nextRef} className="custom-next">
        <img src={ArrowRight} alt="Next" />
      </div>
      <SwiperStyled
        direction={'horizontal'}
        slidesPerView={'auto'}
        freeMode={true}
        scrollbar={true}
        mousewheel={true}
        grabCursor={true}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onSwiper={(swiper) => {
          if (swiper) {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }
        }}
        modules={[FreeMode, Mousewheel, Scrollbar, Navigation]}
      >
        {FXRatesData.map((rate, index) => (
          <SwiperSlide key={index} style={{ width: 'auto' }}>
            <FXTopRateItem
              key={rate.currency}
              currency={rate.currencyCode}
              rate={parseFloat(rate.rate)}
              rateData={rate}
            />
          </SwiperSlide>
        ))}
      </SwiperStyled>
      {t('label_buy_rate_per_aud_full')}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: calc(100% + 24px);
  height: fit-content;
  position: relative;

  font-family: var(--ff-primary);
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: var(--ds-c-black);
  margin-bottom: 16px;

  & .custom-prev {
    position: absolute;
    top: calc(50% - 14px);
    left: -12px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
  }

  & .custom-next {
    position: absolute;
    top: calc(50% - 14px);
    right: 0px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
  }

  & .custom-prev.swiper-button-disabled,
  & .custom-next.swiper-button-disabled {
    opacity: 0;
  }
`;

const SwiperStyled = styled(Swiper)`
  height: fit-content;
  padding-bottom: 12px;
  padding-right: 108px;
  z-index: 0;

  & .swiper-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 21px;

    & .swiper-slide {
      // height: fit-content;
      cursor: pointer;

      @media screen and (min-width: ${TABLET_MIN_WIDTH}px) {
        flex: 1;
      }
    }
  }

  @media screen and (min-width: ${TABLET_MIN_WIDTH}px) {
    padding-right: 24px;
  }

  & .swiper-scrollbar-horizontal {
    display: none;
  }
`;

export default FXRatesSection;
