import SendIcon from 'assets/icons/send-icon.svg';
import DiscountTicketBackground from 'assets/images/discount/discount_ticket_background.png';
import DiscountTicketBackgroundComing from 'assets/images/discount/discount_ticket_background_coming_soon.png';
import DiscountTicketBackgroundDisabled from 'assets/images/discount/discount_ticket_background_disabled.png';
import NoteBlueIco from 'assets/images/discount/note_blue_ico.svg';
import NoteGreyIco from 'assets/images/discount/note_grey_ico.svg';
import NoteWhiteIco from 'assets/images/discount/note_white_ico.svg';
import { handleApplyDiscount } from 'components/Discount/func';
import ButtonCommon from 'components/common/ButtonCommon';
import SizeBox from 'components/common/SizeBox';
import {
  DISCOUNT_ACTIVE,
  DISCOUNT_COMING_SOON,
  DISCOUNT_EXPIRED,
  DISCOUNT_FULLY_REDEEMED,
  DISCOUNT_REDEEMED,
  EN_LANG,
  MOBILE_SMALL_MAX_WIDTH,
  MOBILE_SMALL_MIN_WIDTH,
  ORDER_EMAIL,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import {
  chipStyles,
  datePicker,
  format,
  getChipValue,
  getDiscountStatus,
  getDiscountUnit,
  getValidText,
  textColor,
  textTicketColor,
} from 'helpers';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import { FX_MAKE_PAYMENT_KEY } from 'hooks/fx/useFxAddTransaction';
import useAuth from 'hooks/useAuth';
import useAutoScaleToFitContainer from 'hooks/useAutoScaleToFitContainer';
import useLang from 'hooks/useLang';
import useToastify from 'hooks/useToastify';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { paragraphMixin, textEllipsisSecondLineMixin } from 'styles';
import { stringUtils } from 'utils';
import { DiscountModal } from '../DiscountModal';
import CircleRoundCut from './CircleRoundCut';

const COL_RIGHT_WIDTH = 85;

const DiscountTicket = ({ item, inactive, onApplyError }) => {
  const { t, locate = EN_LANG } = useLang();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { token } = useAuth();

  const [isOpenDiscountModal, setOpenDiscountModal] = useState(false);

  const isCreateTxn = pathname === `${TRANSACTION_ADD_PREFIX}`;

  const {
    code,
    discountMethod,
    discountAmount,
    startDate = 0,
    endDate = 0,
    redeem = 0,
    limitTxnPerCustomer = 0,
    lastRedeemAt = 0,
    descriptions,
  } = item || {};
  const { shortDescription } = descriptions?.[locate] || {};
  const {
    status,
    discountMethodKey,
    isActive,
    isActiveUnlimited,
    isDiscountActiveUsedHidden,
    isComingSoon,
  } = getDiscountStatus(item) || {};

  const isActiveAndAvailable = isActive && !inactive;

  const discountAmountUnitClassName = `discount-ticket-amount-unit-${code}`;
  useAutoScaleToFitContainer({
    className: discountAmountUnitClassName,
  });

  const { triggerToaster } = useToastify({
    content: t('label_discount_applied'),
  });

  const handleSendNow = () => {
    Cookies.remove(MAKE_PAYMENT_KEY);
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
    navigate(`${TRANSACTION_ADD_PREFIX}`);
  };

  const handleOnCloseDiscountModal = () => {
    setOpenDiscountModal(false);
  };

  const handleOnOpenDiscountModal = () => {
    setOpenDiscountModal(true);
  };

  const discountTicketBackground = {
    [DISCOUNT_ACTIVE]: DiscountTicketBackground,
    [DISCOUNT_COMING_SOON]: DiscountTicketBackgroundComing,
    [DISCOUNT_REDEEMED]: DiscountTicketBackgroundDisabled,
    [DISCOUNT_EXPIRED]: DiscountTicketBackgroundDisabled,
    [DISCOUNT_FULLY_REDEEMED]: DiscountTicketBackgroundDisabled,
  };

  const noteIco = {
    [DISCOUNT_ACTIVE]: (
      <NoteIcon src={NoteWhiteIco} onClick={handleOnOpenDiscountModal} />
    ),
    [DISCOUNT_COMING_SOON]: (
      <NoteIcon src={NoteBlueIco} onClick={handleOnOpenDiscountModal} />
    ),
    [DISCOUNT_REDEEMED]: (
      <NoteIcon src={NoteGreyIco} onClick={handleOnOpenDiscountModal} />
    ),
    [DISCOUNT_EXPIRED]: (
      <NoteIcon src={NoteGreyIco} onClick={handleOnOpenDiscountModal} />
    ),
    [DISCOUNT_FULLY_REDEEMED]: (
      <NoteIcon src={NoteGreyIco} onClick={handleOnOpenDiscountModal} />
    ),
  };

  return (
    <>
      {isOpenDiscountModal && (
        <DiscountModal
          isOpen={isOpenDiscountModal}
          discount={item}
          onClose={handleOnCloseDiscountModal}
          onClick={handleSendNow}
          onApplyError={onApplyError}
          inactive={inactive}
        />
      )}

      <DiscoutTicketStyled
        $isComingSoon={isComingSoon}
        $backgroundImage={discountTicketBackground[status]}
        $inactive={inactive}
      >
        <CircleRoundCut isBorderDashedLine={isComingSoon} />
        <DiscountTicketContent>
          <DiscountTicketContentColLeft onClick={handleOnOpenDiscountModal}>
            <DescText
              style={{
                color: textColor()[status],
              }}
            >
              {shortDescription}
            </DescText>
            <SizeBox height={4} />
            <ContentColLeftBottom>
              {!isDiscountActiveUsedHidden ? (
                <ChipWrap style={chipStyles()[status]}>
                  <ChipText>
                    {
                      getChipValue({
                        t,
                        redeem,
                        limitTxnPerCustomer,
                      })[status]
                    }
                  </ChipText>
                </ChipWrap>
              ) : (
                <></>
              )}
              {isActiveUnlimited && lastRedeemAt && (
                <DiscountExpired>
                  <ExpiredText
                    style={{
                      color: textColor()[status],
                    }}
                  >
                    {`${t(
                      'label_offers_last_use'
                    )} ${datePicker.convertToLocalDateTimeWithTimeZone(
                      new Date(lastRedeemAt)
                    )}`}
                  </ExpiredText>
                </DiscountExpired>
              )}
              <DiscountExpired>
                {endDate && (
                  <ExpiredText
                    style={{
                      color: textColor()[status],
                    }}
                    dangerouslySetInnerHTML={{
                      __html: getValidText({
                        t,
                        startDate,
                        endDate,
                        lastRedeemAt,
                      })[status],
                    }}
                  />
                )}
                {noteIco[status]}
              </DiscountExpired>
            </ContentColLeftBottom>
          </DiscountTicketContentColLeft>
          <DiscountTicketContentColRight>
            <ContentColRightBody $isActive={isActiveAndAvailable}>
              <ScaleTextContainer>
                <DiscountAmountUnitText
                  className={discountAmountUnitClassName}
                  style={{
                    color: textTicketColor()[status],
                  }}
                >
                  {format.toAmountStr(Number(discountAmount), false)}
                  <span>{getDiscountUnit(discountMethod)}</span>
                </DiscountAmountUnitText>
              </ScaleTextContainer>
              <DiscountMethodText
                style={{
                  color: textTicketColor()[status],
                }}
              >
                {stringUtils.capitalize(t(discountMethodKey))}
              </DiscountMethodText>
              {isActiveAndAvailable && (
                <>
                  {isCreateTxn ? (
                    <ButtonCommon
                      value={t('button_apply')}
                      color="var(--c-primary)"
                      background="var(--bg-primary)"
                      isFill={true}
                      styles={{
                        width: '76px',
                        height: '32px',
                        paddingInline: '0px',
                      }}
                      onClick={() => {
                        handleApplyDiscount({
                          token,
                          item,
                          onApplyError,
                          onApplySuccess: triggerToaster,
                        });
                      }}
                    />
                  ) : (
                    <ButtonCommon
                      value={t('button_send')}
                      color="var(--c-primary)"
                      background="var(--bg-primary)"
                      isFill={true}
                      styles={{
                        paddingInline: 0,
                        height: '32px',
                        whiteSpace: 'nowrap',
                      }}
                      buttonIconStyles={{
                        float: 'right',
                        height: '32px',
                        gap: '0px',
                        maxWidth: '82.58px',
                        minWidth: '82.58px',
                        // paddingInline: locate === VI_LANG && '0px',
                      }}
                      iconSrc={SendIcon}
                      onClick={() => {
                        Cookies.remove(MAKE_PAYMENT_KEY);
                        Cookies.remove(FX_MAKE_PAYMENT_KEY);
                        Cookies.remove(ORDER_EMAIL);
                        handleSendNow();
                      }}
                    />
                  )}
                </>
              )}
            </ContentColRightBody>
          </DiscountTicketContentColRight>
        </DiscountTicketContent>
      </DiscoutTicketStyled>
    </>
  );
};

const DiscoutTicketStyled = styled.div`
  position: relative;
  margin-top: 8px;
  background-image: ${(props) => `url(${props.$backgroundImage})`};
  background-size: cover;
  height: 100px;
  border-radius: 12px;
  filter: ${(props) => props.$inactive && 'var(--ds-f-discount-inactive)'};

  ${(props) =>
    props.$isComingSoon &&
    `
        border: 2px dashed #A4B2CF;
  `}

  &:first-child {
    margin-top: 0px};
  }

  @media screen and (min-width: ${MOBILE_SMALL_MIN_WIDTH}px) and (max-width: ${MOBILE_SMALL_MAX_WIDTH}px) {
    height: 108px;
  }
`;

const DiscountTicketContent = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;
const DiscountTicketContentColLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16px;
  padding-block: 5px;
  height: 100%;
  width: calc(100% - 132px);
  cursor: pointer;

  @media screen and (min-width: ${MOBILE_SMALL_MIN_WIDTH}px) and (max-width: ${MOBILE_SMALL_MAX_WIDTH}px) {
    margin-left: 8px;
  }
`;
const DiscountTicketContentColRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 8px;
  margin-right: 8px;
  height: 100%;
  width: ${COL_RIGHT_WIDTH}px;
`;
const ContentColRightBody = styled.div`
  display: grid;
  height: 100%;
  align-content: ${(props) => (props.$isActive ? 'end' : 'center')};
  justify-items: center;
`;

const DescText = styled.p`
  ${paragraphMixin};
  ${textEllipsisSecondLineMixin};
  text-align: left;
`;

const ContentColLeftBottom = styled.div``;
const ChipWrap = styled.div``;
const ChipText = styled.p`
  ${paragraphMixin};
  text-align: center;
  font-size: 12px;
  line-height: normal;
  color: #fff;
  font-weight: 600;
`;

const DiscountExpired = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const ExpiredText = styled.p`
  ${paragraphMixin};
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;

const ScaleTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${COL_RIGHT_WIDTH}px;
  height: 32px;
`;
const DiscountAmountUnitText = styled.p`
  ${paragraphMixin};
  text-align: center;
  font-size: 32px;
  line-height: 1;

  & span {
    font-size: 16px;
  }
`;
const DiscountMethodText = styled.p`
  ${paragraphMixin};
  text-align: center;
  font-size: 12px;
  font-weight: 500;
`;

const NoteIcon = styled.img`
  margin-left: 8px;
  cursor: pointer;
`;

export default DiscountTicket;
