import { api } from 'api';
import { handleGetEventsAvailable } from 'components/Events/func';
import { AS_GUEST, AS_REGISTER } from 'constants/fx';
import Cookies from 'helpers/cookies';
import { FX_MAKE_PAYMENT_KEY } from 'hooks/fx/useFxAddTransaction';
import useFxStore from 'hooks/fx/useFxStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useQuery from 'hooks/useQuery';
import { useCallback, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { SET_FX_TOKEN_TRANSACTION } from 'store/fx/action';
import { ORDER_EMAIL, REGISTER_PREFIX } from '../../../constants';
import {
  DASHBOARD_PREFIX,
  EVENT_PREFIX,
  FX_DASHBOARD_PREFIX,
  FX_LOGIN_PREFIX,
  FX_PAYMENT_PROCESS_PREFIX,
  FX_TRANSACTIONS_PREFIX,
  FX_TRANSACTION_ADD_PREFIX,
  HOME_APP_URL,
  LOGIN_PREFIX,
  NOT_FOUND_PREFIX,
  OFFERS_PREFIX,
  PAYMENT_PROCESS_PREFIX,
  PROFILE_PREFIX,
  RECEIVERS_ADD_SUFIX,
  RECEIVERS_EDIT_SUFIX,
  RECEIVERS_PREFIX,
  TRANSACTIONS_PREFIX,
  TRANSACTION_ADD_PREFIX,
} from '../../../constants/router';
import useAuth from '../../../hooks/useAuth';
import useStore from '../../../hooks/useStore';
import { SET_CURRENT_USER, SET_TOKEN_TRANSACTION } from '../../../store/action';

const PersistRoutes = () => {
  const { state, dispatch } = useStore();
  const { dispatch: fxDispatch } = useFxStore();
  const { currentUser } = state;
  const navigate = useNavigate();
  const { token, logout } = useAuth();
  const query = useQuery();
  const { pathname } = useLocation();
  const { isMobile, isTablet } = useDetectDevice();

  const { receiverId } = useParams();

  const paymentProcessRedirect = useCallback(() => {
    const refNumber = query.get('refNumber');
    const tokenTransaction = query.get('token')?.split('?')[0];

    if (
      pathname.includes(FX_PAYMENT_PROCESS_PREFIX) &&
      refNumber &&
      tokenTransaction
    ) {
      fxDispatch({ type: SET_FX_TOKEN_TRANSACTION, payload: tokenTransaction });
      navigate(`${FX_TRANSACTION_ADD_PREFIX}?orderRef=${refNumber}`);
      return;
    }

    if (
      pathname.includes(PAYMENT_PROCESS_PREFIX) &&
      refNumber &&
      tokenTransaction
    ) {
      dispatch({ type: SET_TOKEN_TRANSACTION, payload: tokenTransaction });
      navigate(`${TRANSACTION_ADD_PREFIX}?refNumber=${refNumber}`);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, query]);

  const registerRedirect = useCallback(
    () => {
      const signupId = query.get('signupId');

      if ((isMobile || isTablet) && signupId) {
        return;
      }

      pathname.includes(REGISTER_PREFIX) && navigate(`${REGISTER_PREFIX}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  const loginRedirect = useCallback(() => {
    if (pathname.includes(LOGIN_PREFIX)) {
      navigate(`${LOGIN_PREFIX}`);
    }

    if (
      !pathname.includes(REGISTER_PREFIX) &&
      !pathname.includes(LOGIN_PREFIX)
    ) {
      navigate(`${LOGIN_PREFIX}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const transactionsRedirect = useCallback(() => {
    const tid = query.get('tid');

    if (pathname === `${TRANSACTIONS_PREFIX}` && tid) {
      return;
    }

    pathname.includes(TRANSACTIONS_PREFIX) &&
      navigate(`${TRANSACTIONS_PREFIX}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const addTransactionsRedirect = useCallback(() => {
    const rid = query.get('rid');
    const refNumber = query.get('refNumber');
    const paymentStatus = query.get('paymentStatus');

    if (pathname === `${TRANSACTION_ADD_PREFIX}` && rid) {
      return;
    }

    if (
      pathname === `${TRANSACTION_ADD_PREFIX}` &&
      refNumber &&
      paymentStatus
    ) {
      return;
    }

    if (pathname === `${TRANSACTION_ADD_PREFIX}` && refNumber) {
      return;
    }

    pathname === `${TRANSACTION_ADD_PREFIX}` &&
      navigate(`${TRANSACTION_ADD_PREFIX}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, query]);

  const fxTransactionsRedirect = useCallback(() => {
    const tid = query.get('tid');

    if (pathname === `${FX_TRANSACTIONS_PREFIX}` && tid) {
      return;
    }

    pathname.includes(FX_TRANSACTIONS_PREFIX) &&
      navigate(`${FX_TRANSACTIONS_PREFIX}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const fxAddTransactionsRedirect = useCallback(() => {
    const orderRef = query.get('orderRef');
    const paymentStatus = query.get('paymentStatus');
    const userType = query.get('as');
    const currencyCode = query.get('currencyCode');

    if (
      pathname === `${FX_TRANSACTION_ADD_PREFIX}` &&
      orderRef &&
      paymentStatus
    ) {
      return;
    }

    if (pathname === `${FX_TRANSACTION_ADD_PREFIX}` && orderRef) {
      return;
    }

    if (
      pathname === `${FX_TRANSACTION_ADD_PREFIX}` &&
      userType === AS_REGISTER
    ) {
      return;
    }

    if (
      pathname.includes(`${FX_TRANSACTION_ADD_PREFIX}`) &&
      userType &&
      currencyCode
    ) {
      navigate(FX_TRANSACTION_ADD_PREFIX);
      return;
    }
    pathname === `${FX_TRANSACTION_ADD_PREFIX}` &&
      navigate(`${FX_TRANSACTION_ADD_PREFIX}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, query]);

  const receiversRedirect = useCallback(() => {
    if (pathname.includes(`${RECEIVERS_EDIT_SUFIX}`)) {
      navigate(`${RECEIVERS_EDIT_SUFIX}/${receiverId}`);
      return;
    }

    if (pathname.includes(`${RECEIVERS_ADD_SUFIX}`)) {
      navigate(`${RECEIVERS_ADD_SUFIX}`);
      return;
    }

    pathname.includes(RECEIVERS_PREFIX) && navigate(`${RECEIVERS_PREFIX}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, receiverId]);

  const dashboardRedirect = useCallback(() => {
    if (
      pathname === HOME_APP_URL ||
      pathname.includes(LOGIN_PREFIX) ||
      pathname.includes(REGISTER_PREFIX) ||
      pathname.includes(DASHBOARD_PREFIX) ||
      pathname.includes(FX_DASHBOARD_PREFIX)
    ) {
      navigate(`${DASHBOARD_PREFIX}`);
      return;
    }

    if (
      !(pathname === HOME_APP_URL) &&
      !pathname.includes(LOGIN_PREFIX) &&
      !pathname.includes(REGISTER_PREFIX) &&
      !pathname.includes(DASHBOARD_PREFIX) &&
      !pathname.includes(FX_DASHBOARD_PREFIX) &&
      !pathname.includes(TRANSACTIONS_PREFIX) &&
      !pathname.includes(FX_TRANSACTIONS_PREFIX) &&
      !pathname.includes(RECEIVERS_PREFIX) &&
      !pathname.includes(PROFILE_PREFIX) &&
      !pathname.includes(EVENT_PREFIX) &&
      !pathname.includes(OFFERS_PREFIX) &&
      !(pathname === TRANSACTION_ADD_PREFIX) &&
      !(pathname === FX_TRANSACTION_ADD_PREFIX) &&
      !pathname.includes('payment-process')
    ) {
      navigate(`${NOT_FOUND_PREFIX}`);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const userType = query.get('as');
  const isFxAsGuest = userType === AS_GUEST;
  const fxAddTransactionAsGuestRedirect = useCallback(() => {
    if (pathname.includes(FX_TRANSACTION_ADD_PREFIX) && isFxAsGuest) {
      const orderRef = query.get('orderRef');
      const paymentStatus = query.get('paymentStatus');
      const email = query.get('email');

      if (orderRef && paymentStatus && email) {
        navigate(
          `${FX_TRANSACTION_ADD_PREFIX}?as=${userType}&&orderRef=${orderRef}&&paymentStatus=${paymentStatus}&&email=${email}`
        );
        return;
      }
      if (orderRef && email) {
        navigate(
          `${FX_TRANSACTION_ADD_PREFIX}?as=${userType}&&orderRef=${orderRef}&&email=${email}`
        );
        return;
      }
      Cookies.remove(FX_MAKE_PAYMENT_KEY);
      Cookies.remove(ORDER_EMAIL);
      navigate(`${FX_TRANSACTION_ADD_PREFIX}?as=${userType}`);
    }
  });
  const paymentProcessAsGuestRedirect = useCallback(() => {
    const refNumber = query.get('refNumber');
    const tokenTransaction = query.get('token')?.split('?')[0];
    const orderEmail = Cookies.get(ORDER_EMAIL);

    if (
      pathname.includes(FX_PAYMENT_PROCESS_PREFIX) &&
      refNumber &&
      tokenTransaction
    ) {
      fxDispatch({ type: SET_FX_TOKEN_TRANSACTION, payload: tokenTransaction });
      navigate(
        `${FX_TRANSACTION_ADD_PREFIX}?as=${AS_GUEST}&&orderRef=${refNumber}&&email=${orderEmail}`
      );
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, query]);

  const fxLoginRedirect = useCallback(() => {
    if (pathname.includes(FX_LOGIN_PREFIX)) {
      const email = query.get('email');
      const mobile = query.get('mobile');
      if (email && mobile) {
        Cookies.remove(FX_MAKE_PAYMENT_KEY);
        Cookies.remove(ORDER_EMAIL);
        navigate(`${FX_LOGIN_PREFIX}?email=${email}&&mobile=${mobile}`);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    async function fetchProfile() {
      try {
        const { data } = await api.getProfile(token);

        if (data) {
          const authUser = {
            ...data,
            token: token,
          };
          dispatch({ type: SET_CURRENT_USER, payload: authUser });

          handleGetEventsAvailable(token);

          transactionsRedirect();

          fxTransactionsRedirect();

          paymentProcessRedirect();

          addTransactionsRedirect();

          fxAddTransactionsRedirect();

          receiversRedirect();

          dashboardRedirect();
        }
      } catch (error) {
        console.error(error?.message);

        logout();

        registerRedirect();

        loginRedirect();
      }
    }

    if (token && !currentUser) {
      fetchProfile();
    }

    if (!token) {
      if (pathname.includes(FX_TRANSACTION_ADD_PREFIX) && isFxAsGuest) {
        fxAddTransactionAsGuestRedirect();
        return;
      }

      if (pathname.includes(FX_LOGIN_PREFIX)) {
        fxLoginRedirect();
        return;
      }

      if (pathname.includes(FX_PAYMENT_PROCESS_PREFIX)) {
        paymentProcessAsGuestRedirect();
        return;
      }

      registerRedirect();
      loginRedirect();
    }
  }, [
    token,
    currentUser,
    registerRedirect,
    loginRedirect,
    // transactionsRedirect,
    // receiversRedirect,
    dashboardRedirect,
    // addTransactionsRedirect,
    fxAddTransactionsRedirect,
    paymentProcessRedirect,
  ]);

  return <>{<Outlet />}</>;
};

export default PersistRoutes;
